import React, { useContext, useEffect } from 'react'

import { DataContext } from './DataContext'
import Form from '../../../elem/form/Form'
import FormResultsSummary from '../../../elem/form/FormResultsSummary'
import getFilterParamsFromForm from '../../../../utils/form/getFilterParamsFromForm'
import clearFormFields from '../../../../utils/form/clearFormFields'

const Filter = ({ filterExpanded, syncFilterState, width }) => {
    const {
        filterFields,
        fetchFilterFields,
        fetchResultsCount,
        filterResultsCount,
        filterResultsLoading,
        params,
        setParams,
        formName
    } = useContext(DataContext)

    const onSubmit = (data, e) => {
        const newParams = {
            PageSize: 50,
            Page: 1,
            SortByField: params['well'].SortByField,
            SortOrder: params['well'].SortOrder,
            ...getFilterParamsFromForm(data),
        }
        setParams(newParams, 'well')
        syncFilterState(false)
    }

    const onReset = (getValues, setValue) => {
        clearFormFields(getValues, setValue)
        const newParams = {
            PageSize: 50,
            Page: 1,
            SortByField: params['well'].SortByField,
            SortOrder: params['well'].SortOrder,
        }
        setParams(newParams, 'well')
    }

    useEffect(() => {
        fetchFilterFields()
    }, [])

    return (
        <>
            <FormResultsSummary 
                filterResultsCount={filterResultsCount}
                filterResultsLoading={filterResultsLoading}
                filterExpanded={filterExpanded}
            />
            <div
                className={`explorerFilter ${
                    filterExpanded ? 'is-visible' : 'is-hidden'
                }`}
            >
                <div className="content">
                    <Form
                        formWidth={width}
                        formName={formName}
                        formFields={filterFields}
                        filterResultsCount={filterResultsCount}
                        filterResultsLoading={filterResultsLoading}
                        onSubmit={onSubmit}
                        onReset={onReset}
                        onChange={fetchResultsCount}
                    />
                </div>
            </div>
        </>
    )
}

export default Filter
