import { toast } from 'react-toastify'

const Toast = ({ level, message, alert }) => {
    const displayToasts = alert ? alert : false
    const toastConfig = {
        className: 'toast',
    }
    
    switch (level) {
        case 'success':
            if (displayToasts) {
                toast.success(message, toastConfig)
            }
            console.log(`%c${message}`, 'color: green;')
            break;
        case 'error':
            if (displayToasts) {
                toast.error(message, toastConfig)
            }
            console.log(`%c${message}`, 'color: red;')
            break;
        case 'info':
            if (displayToasts) {
                toast.info(message, toastConfig)
            }
            console.log(`%c${message}`, 'color: blue;')
            break;
        default:
            if (displayToasts) {
                toast.info(message, toastConfig)
            }
            console.log(`%c${message}`, 'color: blue;')
    }
}

export default Toast
