import React from 'react'

import PanelHeader from '../../../elem/panel/PanelHeader'
import MapHeaderControls from './MapHeaderControls'

export default () => {
    return (
        <PanelHeader extraClass={`mapPanelHeader`}>
            <div className="mapHeaderText">
                Map
            </div>
            <MapHeaderControls />
        </PanelHeader>
    )
}