import React, { useContext, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { FaInfoCircle, FaTimes } from 'react-icons/fa'
import dayjs from 'dayjs'

import Tooltip from '../../Tooltip'
import { PanelStyleContext } from '../../panel/PanelStyleContext'

const DateSelectFieldWrapper = ({ helper, setValue, error, register, registerParams, dateSelectProps, watch }) => {
    const { clearInputFieldClass, tooltipClass } = useContext(PanelStyleContext)
    const defaultDate = dateSelectProps.defaultValue ? dateSelectProps.defaultValue : null
    const [date, setDate] = useState(defaultDate ? new Date(defaultDate) : null)

    useEffect(() => {
        const formattedDate = date ? dayjs(date).format('MM/DD/YYYY').toString() : null
        setValue(`${dateSelectProps.name}`, formattedDate)
    }, [date])

    useEffect(() => {
        const updatedFormValue = watch(`${dateSelectProps.name}`)
        if (!updatedFormValue && !!date) {
            setDate(null)
        }
    }, [watch(`${dateSelectProps.name}`)])

    return (
        <div className={`control is-expanded has-icons-right ${clearInputFieldClass}`}>
            {helper && (
                <div data-tip={`${helper}`} data-for={dateSelectProps.name} className="control formHelper">
                <span className="button is-static is-small">
                        <FaInfoCircle />
                        <Tooltip id={dateSelectProps.name} className={`${tooltipClass}`}/>
                    </span>
                </div>
            )}
            <input 
                name={`${dateSelectProps.name}`}
                ref={register(registerParams)}
                value={watch(`${dateSelectProps.name}`, defaultDate ? dayjs(defaultDate).format('MM/DD/YYYY').toString() : '')}
                type="text"
                className={'hidden-input'}
                readOnly
            />
            <DatePicker
                className="input is-small"
                maxDate={new Date()}
                dateFormat="MM/dd/yyyy"
                selected={date}
                showYearDropdown
                showMonthDropdown
                yearDropdownItemNumber={150}
                scrollableYearDropdown
                autoComplete="off"
                onChange={(value) => {
                    setDate(value)
                }}
            />
            <span
                className="icon is-right is-size-7"
                onClick={() => {
                    setValue(dateSelectProps.name, null)
                }}
            >
                <FaTimes />
            </span>
            <div className="help is-danger inputErrors">
                {error && (
                    <span className="inputErrorText">
                        {error.message}
                    </span>
                )}
            </div>
        </div>
    )
}

export default ({
    filterName,
    type,
    error,
    initialDates,
    register,
    registerParams,
    unregister,
    between, 
    setValue,
    fieldName,
    helper,
    watch
}) => {
    // construct dateSelect props
    const dateSelectProps = {
        name: filterName,
        type: type,
        className: 'input is-small',
        defaultValue: initialDates[0],
        ref: register
    }

    if (between) {
        unregister(`${filterName}`)
        const firstDateSelectProps = {
            ...dateSelectProps,
            name: `${dateSelectProps.name}[0]`,
            defaultValue: initialDates[0]
        }
        const secondDateSelectProps = {
            ...dateSelectProps,
            name: `${dateSelectProps.name}[1]`,
            defaultValue: initialDates[1]
        }
        return (
            <div className="betweenInputsWrapper">
                <DateSelectFieldWrapper
                    helper={helper}
                    setValue={setValue}
                    register={register}
                    registerParams={registerParams}
                    watch={watch}
                    dateSelectProps={firstDateSelectProps}
                />
                <span className="betweenAndText">AND</span>
                <DateSelectFieldWrapper
                    helper={helper}
                    setValue={setValue}
                    error={error ? error : null}
                    register={register}
                    registerParams={registerParams}
                    watch={watch}
                    dateSelectProps={secondDateSelectProps}
                />
            </div>
        )
    } else {
        unregister(`${filterName}[0]`)
        unregister(`${filterName}[1]`)
        return (
            <DateSelectFieldWrapper
                helper={helper}
                setValue={setValue}
                error={error}
                register={register}
                registerParams={registerParams}
                dateSelectProps={dateSelectProps}
                watch={watch}
            />
        )
    }
}