import React, { useContext } from 'react'
import { IconContext } from 'react-icons'
import { FaSpinner } from 'react-icons/fa'
import { PanelStyleContext } from './panel/PanelStyleContext'

const Spinner = ({ extraClass }) => {
    const { colorClass } = useContext(PanelStyleContext)
    return (
    <div className={`spinnerWrapper ${extraClass ? extraClass : ''}`}>
        <IconContext.Provider value={{ className: 'fa-pulse ' + colorClass, size: '4em' }}>
            <FaSpinner />
        </IconContext.Provider>
    </div>
)
}

const SimpleSpinner = ({ extraClass }) => (
    <div className={`spinnerWrapper ${extraClass ? extraClass : ''}`}>
        <IconContext.Provider value={{ className: 'fa-pulse', size: '4em' }}>
            <FaSpinner />
        </IconContext.Provider>
    </div>
)

export { SimpleSpinner }
export default Spinner
