
const generateDateParams = (params) => {
    const dates = (params.sample && params.sample.SimpleSampleDateValue) ? decodeURIComponent(params.sample.SimpleSampleDateValue).split(',') : []
    if (!(dates.length)) {
        return [null, null]
    }
    const startDate = dates[0]
    const endDate = dates.length > 1 ? dates[1] : null
    return [startDate, endDate]
}

const roundToFourDigits = (number) => {
    return roundToNDigits(number, 4)
}

const roundToNDigits = (number, n) => {
    return Number(Math.round(Number( number.toPrecision(n)) + `e${n - 1}`) + `e-${n - 1}`)
}

const roundToNDecimals = (number, n) => {
    if (number === 0) {
        return 0
    }
    return Number(Number(Math.round((number + Number.EPSILON) + `e${n}`)) + `e-${n}`)
}

export { generateDateParams, roundToFourDigits, roundToNDigits, roundToNDecimals }