import React, { useContext } from 'react'
import { IconContext } from 'react-icons'
import {
    FaMousePointer,
    FaTimesCircle,
    FaVectorSquare,
    FaDrawPolygon,
    FaInfoCircle,
    FaLayerGroup,
} from 'react-icons/fa'

import { IconTooltip as Tooltip } from '../../../elem/Tooltip'
import { MapControlContext } from './MapControlContext'
import { DataContext } from './DataContext'
import withConfig from '../../../wrappers/withConfig'

const MapHeaderControls = ({ config }) => {
    const {
        activeControl,
        activateControl,
        clearControls,
        displayTooltip,
        toggleTooltip,
        displayMapLayers,
        toggleMapLayers,
    } = useContext(MapControlContext)
    const { clearSelectionLayer } = useContext(DataContext)
    const { FACILITY_TITLE } = config

    const controls = [
        {
            icon: FaMousePointer,
            name: 'select',
            tooltip: `Select ${FACILITY_TITLE}s by Click`,
        },
        {
            icon: FaVectorSquare,
            name: 'boundingBox',
            tooltip: `Select ${FACILITY_TITLE}s by Rectangle`,
        },
        {
            icon: FaDrawPolygon,
            name: 'polygon',
            tooltip: `Select ${FACILITY_TITLE}s by Polygon`,
        },
    ]

    return (
        <div className="panelHeaderControls">
            <div className="panelHeaderIconWrapper">
                <div className="controlsGroupedWrapper">
                    {controls.map((control, idx) => {
                        const { name, icon: Icon, tooltip } = control
                        return (
                            <div
                                key={`icon-${idx}`}
                                className={`panelHeaderIconDiv displayIcon ${
                                    activeControl === name
                                        ? 'active'
                                        : 'inactive'
                                }`}
                                data-tip={`${tooltip}`}
                                data-for={`${name}`}
                            >
                                <IconContext.Provider
                                    value={{
                                        className: `panelHeaderIcon ${
                                            activeControl === name
                                                ? 'active'
                                                : 'inactive'
                                        }`,
                                    }}
                                >
                                    <Icon
                                        onClick={() => {
                                            if (activeControl === name) {
                                                clearControls()
                                            } else {
                                                activateControl(name)
                                            }
                                        }}
                                    />
                                </IconContext.Provider>
                                <Tooltip id={name} />
                            </div>
                        )
                    })}
                </div>
                <div
                    className={`panelHeaderIconDiv displayIcon ${
                        displayTooltip ? 'active' : 'inactive'
                    }`}
                    data-for={'displayTooltip'}
                    data-tip={'Display / Hide Tooltip'}
                >
                    <IconContext.Provider
                        value={{
                            className: `panelHeaderIcon ${
                                displayTooltip ? 'active' : 'inactive'
                            }`,
                        }}
                    >
                        <FaInfoCircle
                            onClick={() => {
                                toggleTooltip(!displayTooltip)
                            }}
                        />
                    </IconContext.Provider>
                    <Tooltip id={'displayTooltip'} />
                </div>
                <div
                    className="panelHeaderIconDiv"
                    data-for={'clearSelection'}
                    data-tip={'Clear Selection'}
                >
                    <IconContext.Provider
                        value={{
                            className: 'mapIcon inactive',
                        }}
                    >
                        <FaTimesCircle
                            onClick={() => {
                                clearControls()
                                clearSelectionLayer()
                            }}
                        />
                    </IconContext.Provider>
                    <Tooltip id={'clearSelection'} />
                </div>
                <div
                    className="panelHeaderIconDiv"
                    data-for={'displayLayers'}
                    data-tip={'Display / Hide Layers'}
                >
                    <IconContext.Provider
                        value={{ className: 'panelHeaderIcon' }}
                    >
                        <FaLayerGroup
                            onClick={() => {
                                toggleMapLayers(!displayMapLayers)
                            }}
                        />
                    </IconContext.Provider>
                    <Tooltip id={'displayLayers'} />
                </div>
            </div>
        </div>
    )
}

export default withConfig(MapHeaderControls)
