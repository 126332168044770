import React, { createContext, useCallback } from 'react'
import { useAuth } from 'oidc-react'
import localForage from 'localforage'
import dayjs from 'dayjs'
import toast from '../elem/Toast'


const APIRequestContext = createContext(null)

const APIRequestContextProvider = ({ children }) => {
    const { userManager } = useAuth()

    const authenticatedFetch = useCallback(async (endpoint, options) => {
        const u = await userManager.getUser()
        let fetchOptions = {}
        if (options) {
            fetchOptions = {
                ...options,
                headers: {
                    ...options.headers,
                    Authorization: `Bearer ${u ? u.access_token : null}`,
                },
            }
        } else {
            fetchOptions = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${u ? u.access_token : null}`,
                },
            }
        }
        return fetch(endpoint, fetchOptions)
    }, [userManager])

    const cacheFetch = useCallback(async (endpoint, options, cacheKey, daysTillExpiration) => {
        let fetchOptions = {}
        if (options) {
            fetchOptions = options
        } else {
            fetchOptions = {
                method: 'GET'
            }
        }
        return new Promise((resolve, reject) => {
            localForage.getItem(cacheKey).then(cachedData => {
                // debugger
                if (cachedData) {
                    const json = JSON.parse(cachedData)
                    if (json && json.data && json.expires) {
                        if (dayjs() < dayjs(json.expires)) {
                            return resolve(json.data)
                        }
                    }
                }
                fetch(endpoint, fetchOptions).then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(async data => {
                    const sessionObject = {
                        expires: dayjs().add(daysTillExpiration, 'day'),
                        data: data,
                    }
                    try {
                        await localForage
                                .setItem(cacheKey, JSON.stringify(sessionObject))
                        return resolve(data)
                    } catch (e) {
                        toast({
                            level: 'info',
                            message:
                                'Cache: Unable to store data locally. This will effect app loading times.',
                        })
                        return reject(e)
                    }
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message:
                            'Cache: ' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.'),
                    })
                    return reject(e)
                })
            })
        })
    }, [])

    return (
        <APIRequestContext.Provider
            value={{authenticatedFetch, cacheFetch}}
        >
            {children}
        </APIRequestContext.Provider>
    )
}

export { APIRequestContext }
export default APIRequestContextProvider
