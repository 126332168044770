import React, {
    useEffect,
    useState,
    createContext,
    useContext,
    useCallback,
    useMemo,
} from 'react'
import get from 'lodash.get'
import deepEqual from 'deep-equal'
import { useHistory, matchPath, useLocation } from 'react-router-dom'

import { APIRequestContext } from '../../wrappers/APIRequestContext'
import withConfig from '../../wrappers/withConfig'
import toast from '../../elem/Toast'
import FacilitySelectionForm from './form/FacilitySelection'
import ReviewSubmission from './form/ReviewSubmission'
// import SkipToSampleResults from './form/SkipToSampleResults'
import urls from '../../../utils/constants/urls'
import { UserContext } from '../../wrappers/UserContext'
import actions from '../../../utils/submissions/actions'
// import merge from 'lodash.merge'
import cloneDeep from 'lodash.clonedeep'
import FacilityAliasForm from './form/FacilityAliasForm'

const DataContext = createContext(null)

const UploadDataContextProvider = ({ children, config }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { user } = useContext(UserContext)
    const [submissionState, setSubmissionState] = useState({})
    const [storedSubmissionState, setStoredSubmissionState] = useState({})
    const [activeAgency, setActiveAgency] = useState(null)
    const [facilityID, setFacilityID] = useState(null)
    const [agencies, setAgencies] = useState(null)
    const [uploadConfig, setUploadConfig] = useState(null)
    const [formTypes, setFormTypes] = useState(null)
    const [navGroups, setNavGroups] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [activePanel, setActivePanel] = useState(null)
    const [errorState, setErrorState] = useState({})
    const [controlledPageSize, setControlledPageSize] = useState({})

    const [targetNavGroup, setTargetNavGroup] = useState({})
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [
        displayUnsavedChangesModal,
        setDisplayUnsavedChangesModal,
    ] = useState(false)

    const [continueAnyway, setContinueAnyway] = useState(false)
    const [
        displayContinueAnywayModal, 
        setDisplayContinueAnywayModal
    ] = useState(false)

    const [formMethods, setFormMethods] = useState({})
    const [formDirty, setFormDirty] = useState(false)
    const [tableData, setTableData] = useState({})
    const { API_URL } = config
    const history = useHistory()
    const { pathname } = useLocation()

    const isReview = useMemo(
        () =>
            !!matchPath(pathname, {
                path: '/submissions/:uploadType/:formType/review/:uploadId',
            }),
        [pathname]
    )
    const viewOnly = useMemo(
        () => !!matchPath(pathname, { path: '/submissions/:uploadType/form/view/' })
    )

    const isEDD = useMemo(
        () => !!matchPath(pathname, { path: '/submissions/:uploadType/edd' })
    )

    const formUrl = useMemo(() => {
        const match = matchPath(pathname, { path: '/submissions/:uploadType'})
        return match && match.params && match.params.uploadType ? match.params.uploadType : null
    }, [pathname])

    useEffect(() => {
        actions.getAgencies(authenticatedFetch, API_URL, setAgencies)
        actions.getSubmissionConfig(
            authenticatedFetch,
            API_URL,
            setUploadConfig
        )
        actions.getFormTypes(
            authenticatedFetch, 
            API_URL, 
            setFormTypes
        )
    }, [])

    // form types
    const formType = useMemo(() => formTypes ? formTypes.find(x => x.Link === formUrl) : {}, [formTypes, formUrl])

    // upload config
    useEffect(() => {
        if (uploadConfig) {
            const groupNames = [
                ...new Set(uploadConfig.map(x => x.SectionGroupName)),
            ]
            const configuredNavGroups = groupNames.map(x => {
                const associatedConfig = uploadConfig.find(
                    y => y.SectionGroupName === x
                )
                const dependentConfig = uploadConfig.find(
                    y => y.DataAccessor === associatedConfig.Requires
                )
                return {
                    groupName: x,
                    requires: associatedConfig.Requires,
                    type: dependentConfig
                        ? dependentConfig.SectionType === 'Form'
                            ? 'object'
                            : 'array'
                        : 'object',
                    dataAccessor: associatedConfig.DataAccessor,
                }
            })
            let allNavGroups = configuredNavGroups
            // if (!viewOnly) {
                allNavGroups = [
                    {
                        groupName: 'Sampling Point Search',
                        requires: null,
                        type: 'component',
                        component: FacilitySelectionForm,
                        dataAccessor: 'facility',
                    },
                    {
                        groupName: 'Sampling Point Alias Information',
                        requires: null,
                        type: 'component',
                        component: FacilityAliasForm,
                        dataAccessor: 'facilityAlias'
                    },
                    ...allNavGroups,
                ]
            // }
            if (isReview) {
                allNavGroups = [
                    ...allNavGroups,
                    {
                        groupName: 'Review',
                        requires: null,
                        type: 'component',
                        component: ReviewSubmission,
                        dataAccessor: 'facility',
                    },
                ]
            }
            setNavGroups(allNavGroups)
        }
    }, [uploadConfig, isReview, viewOnly])

    const initializeState = data => {
        // console.log('initializing state w/ data:', data)
        setStoredSubmissionState(data)
        setSubmissionState(data)
    }

    const resetState = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            if (formMethods && Object.keys(formMethods).length) {
                formMethods.reset()
            }
            setSubmissionState({...storedSubmissionState})
            return resolve()
        })
    }, [storedSubmissionState, formMethods, uploadConfig])

    const submit = useCallback(() => {
        if (submissionState && submissionState.upload) {
            setIsSubmitting(true)
            // then submit the form for validation
            submitForValidation(submissionState, true)
                .then(() => {
                    // if validation is successful, then submit
                    authenticatedFetch(
                        `${API_URL}/upload/submit?uploadId=${submissionState.upload.UploadID}`
                    )
                        .then(() => {
                            history.push(urls.manageSampleResults)
                        })
                        .catch(e =>
                            toast({
                                level: 'error',
                                message:
                                    `Submission Error: ` + e.message,
                                alert: true,
                            })
                        )
                })
                .catch(() => {
                    // otherwise, throw a toast that says they need to fix errors before continuing
                    toast({
                        level: 'error',
                        message:
                            'This form has validation errors. Please fix errors and save the form before submitting.',
                        alert: true,
                    })
                })
                .finally(() => setIsSubmitting(false))
        }
    }, [submissionState, activePanel, formMethods])

    const createUpload = useCallback((agencyCode) => {
        actions.create(
            authenticatedFetch,
            API_URL,
            history,
            formType,
            agencyCode,
            user.profile.name
        )
    }, [formType, user])

    const submitForValidation = useCallback(
        (data, force) => {
            return new Promise((resolve, reject) => {
                if (
                    (data && !isSubmitting && 
                        Object.keys(data).length &&
                        formMethods &&
                        Object.keys(formMethods).length &&
                        !deepEqual(storedSubmissionState, data)) ||
                    force
                ) {
                    setIsSubmitting(true)
                    // console.log(
                    //     'submitting because of the following differences in state:',
                    //     diff(submissionState, storedSubmissionState)
                    // )
                    authenticatedFetch(`${API_URL}/upload`, {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Headers':
                                'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                        },
                        body: JSON.stringify(data),
                    })
                        .then(async response => {
                            if (response.ok) {
                                return response.json()
                            } else {
                                const error = await response.text()
                                throw new Error(error)
                            }
                        })
                        .then(response => {
                            // if there is a submission, set the facilityID to the facilityID of the submission
                            if (
                                response.submission &&
                                response.submission.facility.UploadFacilityID
                            ) {
                                setFacilityID(
                                    response.submission.facility
                                        .UploadFacilityID
                                )
                            }

                            // if there are errors in this group, set the errors
                            // on the associated fields and prevent
                            if (
                                (response.errors &&
                                    Object.keys(response.errors).length) ||
                                (response.formErrors &&
                                    Object.keys(response.formErrors).length)
                            ) {
                                const errors = cloneDeep(response.errors)
                                const formErrors = cloneDeep(
                                    response.formErrors
                                )
                                // const allErrors = merge(errors, formErrors)
                                const errorKeys = new Set(
                                    [
                                        ...Object.keys(formErrors),
                                        ...Object.keys(errors),
                                    ]
                                )
                                const allErrors = [...errorKeys].reduce(
                                    (acc, curr) => {
                                        const fe = formErrors[curr]
                                            ? formErrors[curr]
                                            : []
                                        const e = errors[curr]
                                            ? errors[curr]
                                            : []
                                        const keyErrors = [...fe, ...e]
                                        return {
                                            ...acc,
                                            [curr]: keyErrors,
                                        }
                                    },
                                    {}
                                )
                                // console.log('errorState:', allErrors)
                                setErrorState(allErrors)

                                // if we're forcing validation, then reject if there are severity = 'Error'
                                // but pass if all errors are severity = 'Warning'
                                if (force) {
                                    if (
                                        Object.keys(allErrors).filter(x =>
                                            allErrors[x].some(
                                                x => x.severity === 'Error'
                                            )
                                        ).length
                                    ) {
                                        return reject()
                                    }
                                }
                                initializeState(response.submission)
                                setFormDirty(false)
                            } else {
                                if (
                                    formMethods &&
                                    Object.keys(formMethods).length
                                ) {
                                    formMethods.clearError()
                                }
                                setErrorState({})
                                initializeState(response.submission)
                                setFormDirty(false)
                                return resolve()
                            }
                            return resolve()
                        })
                        .catch(e => {
                            toast({
                                level: 'error',
                                message:
                                    'Submission: ' +
                                    (e.message
                                        ? e.message
                                        : 'Unable to connect to the server. Please try again later.'),
                            })
                            return reject()
                        })
                        .finally(() => setIsSubmitting(false))
                } else {
                    return resolve()
                }
            })
        },
        [storedSubmissionState, formMethods, uploadConfig, isSubmitting]
    )

    useEffect(() => {
        if (!isEDD) {
            submitForValidation(submissionState).catch(() => {})
        }
    }, [submissionState, isEDD])

    const changeActivePanel = async activePanel => {
        if (formMethods && formMethods.reset && formMethods.getValues) {
            try {
                await formMethods.reset()
            } catch (e) {
                console.log('e:', e)
            }
        }
        setActivePanel(activePanel)
    }

    const triggerValidation = useCallback(
        group => {
            return new Promise((resolve, reject) => {
                checkForUnsavedChanges(group)
                    .then(async () => {
                        if (formMethods && Object.keys(formMethods).length) {
                            try {
                                await formMethods.triggerValidation()
                                const formErrors = get(
                                    formMethods.errors,
                                    group.dataAccessor
                                )
                                if (
                                    ((formErrors &&
                                        Object.keys(formErrors).length !== 0)) ||
                                    (Object.keys(
                                        errorState
                                    ).filter(x =>
                                        errorState[x].some(
                                            x => x.severity === 'Error'
                                        )
                                    ).length)
                                ) {
                                    toast({
                                        level: 'error',
                                        message:
                                            'This form has validation errors. Please fix errors and save the form before submitting.',
                                        alert: true,
                                    })
                                    return reject()
                                }
                            } catch (e) {
                                return reject()
                            }
                        }
                        return resolve()
                    })
                    .catch(() => {
                        return reject()
                    })
            })
        },
        [formMethods, formDirty, errorState]
    )

    const checkForUnsavedChanges = useCallback(
        group => {
            return new Promise((resolve, reject) => {
                // if there is a form, then we need to check it for dirty state
                if (formMethods && Object.keys(formMethods).length && !viewOnly) {
                    // if the form has been touched, we want to reject and display the unsaved changes
                    // banner
                    if (formDirty) {
                        setUnsavedChanges(true)
                        setDisplayUnsavedChangesModal(true)
                        if (group) {
                            setTargetNavGroup(group)
                        }
                        return reject()
                    } else {
                        // otherwise, we hide the banner notification and
                        // resolve
                        setUnsavedChanges(false)
                        setDisplayUnsavedChangesModal(false)
                        return resolve()
                    }
                } else {
                    // otherwise, there are no unsaved changes and we continue + hide
                    // the notification banner
                    setUnsavedChanges(false)
                    setDisplayUnsavedChangesModal(false)
                    return resolve()
                }
            })
        },
        [formMethods, formDirty, viewOnly]
    )

    const navigate = useCallback(
        group => {
            return new Promise((resolve, reject) => {
                // triggerValidation(group)
                checkForUnsavedChanges(group)
                    .then(() => resolve())
                    .catch(() => reject())
            })
        },
        [submissionState, formMethods, triggerValidation, checkForUnsavedChanges]
    )

    const discardUnsavedChanges = useCallback(() => {
        resetState()
            .then(() => {
                setFormDirty(false)
                changeActivePanel(targetNavGroup.groupName)
            })
            .then(setDisplayUnsavedChangesModal(false))
    }, [resetState, targetNavGroup])

    const returnToList = useCallback(() => {
        return new Promise((resolve, reject) => {
            const group = navGroups.find(x => x.groupName === activePanel)
            if (group) {
                if (formMethods && Object.keys(formMethods).length && !viewOnly) {
                    // if the form has been touched, we want to reject and display the unsaved changes
                    // banner
                    if (formDirty) {
                        setContinueAnyway(true)
                        setDisplayContinueAnywayModal(true)
                        return reject()
                    } else {
                        setContinueAnyway(false)
                        setDisplayContinueAnywayModal(false)
                        return resolve()
                    }
                } else {
                    setContinueAnyway(false)
                    setDisplayContinueAnywayModal(false)
                    return resolve()
                }
            } else {
                setContinueAnyway(false)
                setDisplayContinueAnywayModal(false)
                return resolve()
            }
        })
    }, [triggerValidation, navGroups, activePanel, formDirty, formMethods, formMethods.errors])


    const [displayBeforePageChangeModal, setDisplayBeforePageChangeModal] = useState(false)
    const [pageChangeAction, setPageChangeAction] = useState(false)
    
    const beforePageChange = useCallback(action => {
            if (formMethods && Object.keys(formMethods).length && !viewOnly) {
                // if the form has been touched, we want to reject and display the unsaved changes
                // banner
                if (formDirty) {
                    setDisplayBeforePageChangeModal(true)
                    setPageChangeAction(prev => action)
                } else {
                    setDisplayBeforePageChangeModal(null)
                    setPageChangeAction(null)
                    action()
                    return 
                }
            } else {
                setDisplayBeforePageChangeModal(null)
                setPageChangeAction(null)
                action()
                return
            }
        }, [triggerValidation, navGroups, activePanel, formDirty, formMethods, formMethods.errors])

    const stampLatLong = useCallback(() => {
        if (formMethods && Object.keys(formMethods).length) {
            const group = navGroups.find(x => x.groupName === activePanel)
            if (group && group.dataAccessor) {
                const values = formMethods.getValues()
                const dataAccessor = group.dataAccessor
                if (Object.keys(values).some(x => x.includes('Latitude') && Object.keys(values).some(x => x.includes('Longitude')))) {
                    const lat = values[`${dataAccessor}.Latitude`]
                    const long = values[`${dataAccessor}.Longitude`]
                    authenticatedFetch(`${API_URL}/upload/geoStamp?latitude=${lat}&longitude=${long}`)
                        .then(async response => {
                            if (response.ok) {
                                return response.json()
                            } else {
                                const error = await response.text()
                                throw new Error(error)
                            }
                        })
                        .then(response => {
                            Object.keys(response).forEach(key => {
                                const formInputName = Object.keys(values).find(v => {
                                    return v.endsWith(key) || v.endsWith(key + 'Select')
                                })
                                const value = response[key]
                                if (value !== null && value !== "") {
                                    if (formInputName) {
                                        if (formInputName.includes('Select')) {
                                            const configField = uploadConfig.find(x => x.ControlName === key)
                                            const option = configField.Values.find(x => x.code.toString() === value.toString())
                                            formMethods.setValue(formInputName, {value: option.code, label: option.codedescription, active: !!option.active})
                                        } else {
                                            formMethods.setValue(formInputName, value)
                                        }
                                    }
                                }
                            })
                        })
                        .catch(e => {
                            // console.log('tried to execute lat long stamp with bad values')
                        })
                }
            }
        }
    }, [submissionState, formMethods, navGroups, activePanel])


    const [ pageErrorText, setPageErrorText ] = useState({})

    useEffect(() => {
        const o = Object.keys(errorState).filter(x => x.includes('[')).reduce((acc, curr) => {
            const tableName = curr.split('[')[0]
            const idx = curr.replace(tableName, '').replace('[', '').replace(']', '')
            const errors = errorState[curr]
            if (errors.filter(x => x.fieldName !== "" && x.severity === "Error").length) {

                if (acc[tableName]) {
                    return {
                        ...acc,
                        [tableName]: [
                            ...acc[tableName],
                            idx
                        ]
                    }
                } else {
                    return {
                        ...acc,
                        [tableName]: [idx]
                    }
                }
            }
            else {
                return acc
            }
        }, {})
        const textObject = Object.keys(o).reduce((acc, curr) => {
            const pagesWithErrors = o[curr].map(x => Number(x)).map(x => (Math.floor(x / controlledPageSize[curr]) + 1))
            return {
                ...acc,
                [curr]: `Record(s) have errors in the following pages: ${[...pagesWithErrors.sort()].join(', ')}`
            }
        }, {})
        
        setPageErrorText(textObject)
    }, [controlledPageSize, errorState])

    const updateControlledPageSize = useCallback((accessor, size) => {
        setControlledPageSize(prevCPS => ({
            ...prevCPS,
            [accessor]: size
        }))
    }, [setControlledPageSize])


    return (
        <DataContext.Provider
            value={{
                agencies,
                uploadConfig,
                formType,
                activeAgency,
                setActiveAgency,
                activePanel,
                setActivePanel,
                changeActivePanel,
                navGroups,
                submissionState,
                storedSubmissionState,
                setSubmissionState,
                formMethods,
                setFormMethods,
                formDirty,
                setFormDirty,
                facilityID,
                setFacilityID,
                initializeState,
                navigate,
                returnToList,
                submit,
                createUpload,
                resetState,
                isReview,
                viewOnly,
                tableData,
                unsavedChanges,
                displayUnsavedChangesModal,
                setDisplayUnsavedChangesModal,
                discardUnsavedChanges,
                continueAnyway,
                setContinueAnyway,
                setDisplayContinueAnywayModal,
                displayContinueAnywayModal,
                setDisplayBeforePageChangeModal,
                displayBeforePageChangeModal,
                beforePageChange,
                pageChangeAction,
                isSubmitting,
                errorState,
                setTableData,
                submitForValidation,
                stampLatLong,
                updateControlledPageSize,
                pageErrorText
            }}
        >
            {children}
        </DataContext.Provider>
    )
}

export { DataContext }
export default withConfig(UploadDataContextProvider)
