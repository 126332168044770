import React from 'react'
import ReactTooltip from 'react-tooltip'

class Tooltip extends React.PureComponent {
    
    render() {
        const {id, extraClass, stayOpen, place } = this.props
        return <ReactTooltip
            id={id}
            className={`tooltipHover ${extraClass ? extraClass : ""}`}
            place={`${place ? place : 'top'}`}
            delayHide={stayOpen ? 250 : 0}
            effect="solid"
            overridePosition={({left, top}, currentEvent, currentTarget, node) => {
                const { width: tipWidth } = node.getBoundingClientRect();
                const tooltipWidth = parseInt(tipWidth, 10)
                
                // if the tooltip width extends past the 
                // left side of the screen, move it to left: 0
                const iconXOffset = currentEvent.x
                const overflowX = iconXOffset - (tooltipWidth / 2)
                if (overflowX < 0) {
                    return {left: 0, top}
                }
                return {left, top}
            }}
            html={true}
        />
    }
}

const DefaultTooltip = ({id, className}) => (
    <Tooltip extraClass={className} id={id} stayOpen={true} />
)

const IconTooltip = ({id, place}) => (
    <Tooltip id={id} place={place} extraClass={'iconTooltip'} stayOpen={false} />
)

export default DefaultTooltip

export { DefaultTooltip, IconTooltip, Tooltip }