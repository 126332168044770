import qs from 'qs'

const queryStringParser = queryString =>
    qs.parse(decodeURIComponent(queryString), { ignoreQueryPrefix: true })

const getQueryStringFromParams = (params, map) => {
    let queryStrings = []
    Object.keys(params).forEach(paramGroup => {
        const relatedParams = params[paramGroup]
        const relatedParamQueryString = Object.keys(relatedParams) // loop over param keys
            .filter(key => (relatedParams[key] ? true : false)) // filter out undefined params
            .filter(key => (map ? key !== 'PageSize' && key !== 'Page' : true))
            .map(
                key =>
                    `${paramGroup}${key}=${encodeURIComponent(
                        relatedParams[key]
                    )}`
            ) // encode all params
            .join('&')
        queryStrings = queryStrings.concat([relatedParamQueryString])
    })
    const queryString = queryStrings.join('&')

    // in the case that all queryStrings are "", just return an empty string
    if (queryString === '&') {
        return ''
    }
    return queryString
}

const removeOrderAndPageParamsFromOtherFields = (params, formName) => {
    const formNames = Object.keys(params)
    const thisFormParams = params[formName]
    const otherFormParams = formNames
        .filter(name => name !== formName)
        .reduce((acc, currentName) => {
            const formParams = params[currentName]
            return {
                ...acc,
                [currentName]: Object.keys(formParams).reduce((paramAcc, param) => {
                    if (
                        param.toLowerCase().includes('sort') ||
                        param.toLowerCase().includes('page')
                    ) {
                        return paramAcc
                    } else {
                        return {
                            ...paramAcc,
                            [param]: formParams[param],
                        }
                    }
                }, {}),
            }
        }, {})
    return {
        [formName]: thisFormParams,
        ...otherFormParams,
    }
}

const removeOrderAndPageParams = (params, formName) => {
    if (params && params[formName]) {
        return Object.keys(params[formName]).reduce((acc, param) => {
            if (
                param.toLowerCase().includes('sort') ||
                param.toLowerCase().includes('page')
            ) {
                return acc
            } else {
                return {
                    ...acc,
                    [param]: params[formName][param],
                }
            }
        }, {})
    } else {
        return {}
    }
}

export { queryStringParser, getQueryStringFromParams, removeOrderAndPageParams, removeOrderAndPageParamsFromOtherFields }
export default queryStringParser
