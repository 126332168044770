import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react'

import { DataContext } from '../DataContext'
import { MapControlContext } from '../MapControlContext'
import TooltipLoading from './TooltipLoading'
import TooltipData from './TooltipData'
import { getDisplayStyleProps } from '../mapConfig'

export default ({ e, pixel, id }) => {
    const [tooltipRef, setTooltipRef] = useState(null)
    const { tooltipData, tooltipFilterData, tooltipLoading } = useContext(
        DataContext
    )
    const { activeControl } = useContext(MapControlContext)

    const onRefChange = useCallback(node => {
        setTooltipRef(node)
    })

    // display logic 
    const [display, setDisplay] = useState('none')
    
    // when a new feature is highlighted,
    // set display block
    useEffect(() => {
        if ((id && !activeControl) || (id && activeControl === 'select')) {
            setDisplay('block')
        }
        else {
            setDisplay('none')
        }
    }, [id])
    
    const styleProps = useMemo(() => {
        if (e && pixel && tooltipRef) {
            return getDisplayStyleProps(e, pixel, tooltipRef)
        }
    }, [e, pixel, tooltipRef])

    // when the tooltip loads,
    // setup mouseenter and mouseleave
    // functions to ensure mouseover
    // prevents tooltip from hiding
    useEffect(() => {
        if (tooltipRef) {
            tooltipRef.onmouseenter = () => setDisplay('block')
            tooltipRef.onmouseleave = () => setDisplay('none')
        }
    }, [tooltipRef])

    return (
        <div
            className="tooltipWrapper"
            style={{
                ...styleProps,
                display,
            }}
            ref={onRefChange}
        >
            {tooltipLoading ? (
                <TooltipLoading />
            ) : (
                <TooltipData
                    tooltipData={tooltipData}
                    tooltipFilterData={tooltipFilterData}
                />
            )}
        </div>
    )
}
