import React, { useContext } from 'react'
import { UpArrow, DownArrow } from '../../Arrows'
import { PanelStyleContext } from '../../panel/PanelStyleContext'

export default ({ headerGroups }) => {
    const { headerIconClass } = useContext(PanelStyleContext)
    return (
        <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th
                            {...column.getHeaderProps(
                                column.getSortByToggleProps()
                            )}
                        >
                            <div className={`explorerTableHeader ${headerIconClass}`}>
                                {column.render('Header')}
                                <span className="explorerTableArrow">
                                    {column.isSorted ? (
                                        column.isSortedDesc ? (
                                            <DownArrow size={'1.1rem'} />
                                        ) : (
                                            <UpArrow size={'1.1rem'} />
                                        )
                                    ) : (
                                        ''
                                    )}
                                </span>
                            </div>
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    )
}
