import React, { useEffect, useRef, useContext, useState } from 'react'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import Vector from 'ol/source/Vector'
import VectorImage from 'ol/layer/VectorImage'
import GeoJSON from 'ol/format/GeoJSON'
import { transform } from 'ol/proj'
import { unByKey } from 'ol/Observable'
import { DataContext } from './DataContext'
import { MapControlContext } from './MapControlContext'
import Spinner from '../../../elem/Spinner'
import styleFunction from './mapStyles'
import { zoomControls, displayPopup } from './mapConfig'
import MapTooltip from './tooltip/MapTooltip'
import getLayer from './layers/getLayer'
import withConfig from '../../../wrappers/withConfig'
import { AppStateContext } from '../AppStateContext'
import { APIRequestContext } from '../../../wrappers/APIRequestContext'
// import { facilityLegendStyles } from './mapStyles'

const ExplorerMap = ({ config }) => {
    const {
        data,
        loading,
        filteredData,
        fetchTooltipData,
        layerData,
    } = useContext(DataContext)
    const {
        controls,
        activeControl,
        displayTooltip,
        layerState,
        setLayerState,
    } = useContext(MapControlContext)
    const {
        setMapState,
        mapState: { selectedFeatures },
    } = useContext(AppStateContext)
    const { cacheFetch } = useContext(APIRequestContext)

    const { API_URL, MAP, FACILITY_TITLE, ID_COLUMN } = config
    const mapRef = useRef(null)
    const [map, setMap] = useState(null)
    const [unthemedDataLayer, setUnthemedDataLayer] = useState(null)
    const [source, setSource] = useState(null)
    const [eventKeys, setEventKeys] = useState({
        boundingBox: [],
        polygon: [],
        select: [],
    })
    const [popupKey, setPopupKey] = useState(null)
    const [tooltipState, setTooltipState] = useState(null)

    // on load, set up the map
    useEffect(() => {
        const map = new Map({
            target: mapRef.current,
            controls: zoomControls(MAP.ZOOM_TO_EXTENT),
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            view: new View({
                projection: 'EPSG:3857',
                center: transform(
                    MAP.CENTER_LAT_LNG,
                    'EPSG:4326',
                    'EPSG:3857'
                ),
                zoom: MAP.INITIAL_ZOOM_LEVEL,
            }),
        })
        setMap(map)
        setMapState(prevMapState => ({ ...prevMapState, map }))
    }, [null])

    // scale size of points based on zoom level
    const getStyleFunction = (themed, feature) => {
        let zoom = map.getView().getZoom() - 6
        if (zoom > 7) zoom = 7
        if (zoom < 0) zoom = 0
        return styleFunction(themed, feature, zoom)
    }

    // on data load, set the map vector source + layer
    useEffect(() => {
        if (data.type === 'FeatureCollection') {
            // get features from geojson data
            let features = new GeoJSON()
                .readFeatures(data)
                .map(x => {
                    x.getGeometry().transform('EPSG:4326', 'EPSG:3857')
                    x.set('selected', 0)
                    x.set('displayed', 1)
                    x.set('baseLayer', true)
                    return x
                })
            const s = new Vector({
                features,
            })
            setSource(s)
            setMapState(prevMapState => ({
                ...prevMapState,
                allFeatures: features,
            }))

            // add the basic facility layer
            const dataLayerUnthemed = new VectorImage({
                source: s,
                visible: true,
                style: getStyleFunction.bind(this, false),
                minResolution: 0,
                zIndex: 100,
                // opacity: 1
            })
            dataLayerUnthemed.set('name', 'Quarter Township')
            setLayerState(prevLayerState => ({
                ...prevLayerState,
                'Quarter Township': {
                    display: true,
                    expanded: false,
                    unique: true,
                    LayerName: 'Quarter Township',
                    layerGroupName: `${FACILITY_TITLE}s`,
                    Info: `This layer must be active to allow for ${FACILITY_TITLE} selection in the map`,
                    LayerType: 'FEATURE',
                    loaded: true,
                    StyleObj: JSON.stringify({
                        fillColor: 'rgba(61,61,61,0)',
                        strokeColor: 'rgba(61, 61, 61, 0.5)',
                        strokeWidth: 2,
                    }),
                },
            }))
            map.removeLayer(unthemedDataLayer)
            setUnthemedDataLayer(dataLayerUnthemed)
            map.addLayer(dataLayerUnthemed)

            // zoom the map to the extent of the unthemedDataLayer
            // 
        }
    }, [data])

    useEffect(() => {
        if (map && source) {
            try {
                map.getView().fit(source.getExtent(), {padding: [20, 40, 20, 40]})
            } catch {}
        }
    }, [map, source])

    // on layer info load, add layers to the map
    useEffect(() => {
        if (map && layerData) {
            async function fetchLayers() {
                Object.keys(layerData).forEach(async key => {
                    const layers = layerData[key]
                    for (let idx = 0; idx < layers.length; idx ++) {
                        const layer = layers[idx]
                        // debugger
                        const newLayer = await getLayer(layer, API_URL, cacheFetch)
                        if (newLayer) {
                            const layerName = layer.LayerName
                            newLayer.set('name', layerName)
                            newLayer.set('type', layer.LayerType)
                            map.addLayer(newLayer)
                            setLayerState(prevLayerState => ({
                                ...prevLayerState,
                                [layerName]: {
                                    ...prevLayerState[layerName],
                                    loaded: true,
                                },
                            }))
                        }
                    }
                    // layers.forEach(async layer => {
                    // })
                })
            }
            fetchLayers()
        }
    }, [layerData, map, API_URL, cacheFetch])

    // when layer state changes, update layer visibilites
    useEffect(() => {
        if (map && layerData && Object.keys(layerState).length) {
            map.getLayers().forEach(layer => {
                const layerName = layer.get('name')
                if (layerName && layerState[layerName]) {
                    layer.setVisible(layerState[layerName].display)
                }
            })
        }
    }, [map, layerData, layerState])

    // update the base layer when filteredData changes
    useEffect(() => {
        if (data.type === 'FeatureCollection') {
            const newSelectedFeatures = []
            if (filteredData.length) {
                source.getFeatures().map(x => {
                    const id = x.get(ID_COLUMN)
                    // if the filter hides the feature, set the displayed
                    // property to 0
                    const isDisplayed =
                        filteredData.indexOf(id) > -1
                    x.set('displayed', isDisplayed ? 1 : 0)
                    // if the feature is still displayed, carry over the selected
                    // property from selectedFeatures.

                    if (isDisplayed) {
                        const selectedFeature =
                        selectedFeatures.find(f => f.get(ID_COLUMN) === id)

                        if (selectedFeature) {
                            x.set('selected', 1)
                            newSelectedFeatures.push(x)
                        } else {
                            x.set('selected', 0)
                        }
                    } else {
                        x.set('selected', 0)
                    }
                    return x
                })
            } else {
                source.getFeatures().map(x => {
                    // if there is no filter, display all features
                    // and carry over the selected props from selectedFeatures state
                    x.set('displayed', 1)
                    const id = x.get(ID_COLUMN)
                    const selectedFeature =
                        selectedFeatures.find(f => f.get(ID_COLUMN) === id)
                    if (selectedFeature) {
                        x.set('selected', 1)
                        newSelectedFeatures.push(x)
                    } else {
                        x.set('selected', 0)
                    }
                    return x
                })
            }
            // update the selectedFeatures list to include
            // carry over features
            setSelectedFeatures(newSelectedFeatures)

            // console.log(
            //     `Total points: ${data.features.length}, Filtered Points: ${
            //         filteredData.length
            //     }, Features: ${source.getFeatures().length}`
            // )
        }
    }, [filteredData])

    useEffect(() => {
        if (map) {
            const layers = map.getLayers()
            if (filteredData.length) {
                layers.forEach(layer => {
                    if (layer.get('type') === 'THEME') {
                        layer.getSource().getFeatures().map((x, idx) => {
                            const id = x.get(ID_COLUMN)
                            // if (idx === 0) {
                            //     console.log('id:', id, 'x:', x, 'filteredData:', filteredData)
                            // }
                            const isDisplayed = filteredData.find(y => y === id) ? 1 : 0
                            return x.set('displayed', isDisplayed)
                        })
                    }
                    return null
                })
            } else {
                layers.forEach(layer => {
                    if (layer.get('type') === 'THEME') {
                        layer.getSource().getFeatures().map(x => x.set('displayed', 1))
                    }
                    return null
                })
            }
        }
    }, [filteredData, layerState])

    // update size on load finished
    useEffect(() => {
        if (map) {
            map.updateSize()
        }
    }, [loading])

    // add / remove interactions on control change
    useEffect(() => {
        if (map) {
            // remove all other controls
            Object.keys(controls).map(controlName => {
                const control = controls[controlName]
                map.removeInteraction(control.control)
                if (control.layer) {
                    control.layer.setVisible(false)
                }
                return null
            })

            // set control
            if (activeControl) {
                const control = controls[activeControl]
                map.addInteraction(control.control)
                if (control.layer) {
                    control.layer.setVisible(true)
                }
            }
        }
    }, [activeControl])

    // tie control eventListeners to source when it changes
    useEffect(() => {
        // remove existing keys
        Object.keys(eventKeys).map(controlName => {
            const keys = eventKeys[controlName]
            keys.map(key => {
                unByKey(key)
                return null
            })
            return null
        })

        // loop over controls and set the on events for each
        const newEventKeys = Object.keys(controls).reduce(
            (acc, controlName) => {
                const control = controls[controlName]
                if (control.on) {
                    const keys = control.on(source, setSelectedFeatures)
                    return {
                        ...acc,
                        [controlName]: keys,
                    }
                } else {
                    return acc
                }
            },
            {}
        )
        setEventKeys(newEventKeys)
    }, [source])

    // watch the selected features map state and update selectedFeatures on
    // update
    useEffect(() => {
        if (map) {
            updateSelectedFeatures(selectedFeatures)
        }
    }, [selectedFeatures])

    // remove popover when displayTooltip is
    // set to false
    useEffect(() => {
        if (map) {
            unByKey(popupKey)
            if (displayTooltip || activeControl === 'select') {
                const key = map.on('pointermove', e =>
                    displayPopup(e, map, setTooltipState, fetchTooltipData, ID_COLUMN)
                )
                setPopupKey(key)
            }
        }
    }, [displayTooltip, map, activeControl])

    const setSelectedFeatures = features => {
        // update the features
        setMapState(currentMapState => ({
            ...currentMapState,
            selectedFeatures: features,
        }))
    }

    const updateSelectedFeatures = selectedFeatures => {
        // clear selected features
        const features = source.getFeatures()
        features.map(feature => feature.set('selected', 0))

        // loop through the features provided to the function
        // and set 'selected' property to 1
        // features.map(feature => selectedFeatures.includes(feature.get('FacilityID')) ? feature.set('selected', 1) : null)
        selectedFeatures.map(feature => feature.set('selected', 1))
    }

    return (
        <>
            <div className="wellWrapper">
                {loading ? <Spinner /> : null}
                <div
                    id="map"
                    ref={mapRef}
                    className={`wellMapContainer ${loading ? 'is-hidden' : ''}`}
                ></div>
                <MapTooltip {...tooltipState} />
            </div>
        </>
    )
}

export default withConfig(ExplorerMap)
