import React from 'react'

import { PanelStyleContext } from './PanelStyleContext'
export default ({ extraClass, children }) => (
    <PanelStyleContext.Consumer>
        {value => (
            <div
                className={`dashboardPanelHeader ${
                    extraClass ? extraClass : ''
                } ${
                    value.backgroundColorClass ? value.backgroundColorClass : ''
                }`}
            >
                {children}
            </div>
        )}
    </PanelStyleContext.Consumer>
)
