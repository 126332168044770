import React from 'react'

export default ({ filterExpanded, toggleFilter }) =>
    !filterExpanded && (
        <div className="noDataComponentWrapper">
            <div className="noDataComponent">
                <span className="noDataText">No results found. Please</span>
                <span
                    onClick={() => toggleFilter(true)}
                    className="noDataResetFilterText"
                >
                    refine filter results
                </span>
            </div>
        </div>
    )
