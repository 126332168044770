import React, { useContext } from 'react'

import { formatValue } from '../../../../../utils/table/parseColumns'
import { PanelStyleContext } from '../../../../elem/panel/PanelStyleContext'
import { Tooltip } from '../../../../elem/Tooltip'
import withConfig from '../../../../wrappers/withConfig'
import { AppStateContext } from '../../AppStateContext'

const mapDataToLabels = props => {
    return Object.keys(props.formFields).reduce((acc, fieldName) => {
        const field = props.formFields[fieldName]
        const fieldLabel = field.Prompt
        const fieldColumn = field.ColumnName
        const fieldValue = props.data[fieldColumn]
        return {
            ...acc,
            [fieldColumn]: {
                fieldLabel,
                fieldValue,
            },
        }
    }, {})
}

const FieldComponent = ({ fieldLabel, fieldValue, size }) => {
    const textSize = size ? size : '7'
    if (fieldValue === null || typeof fieldValue === 'undefined') {
        return null
    }
    return (
        <div className="field is-horizontal">
            <div className={`label is-size-${textSize}`}>{fieldLabel}</div>
            <div
                className={`value margin-left-sm is-size-${textSize}`}
                dangerouslySetInnerHTML={{
                    __html: formatValue(fieldValue, true),
                }}
            />
        </div>
    )
}

const WellLinkComponent = ({ fieldLabel, fieldValue, linkId, detailLinkURL }) => {
    const appContext = useContext(AppStateContext)
    if (!appContext) {
        return (
            <FieldComponent fieldLabel={fieldLabel} fieldValue={fieldValue} />
        )
    }
    return (
        <div 
            className="field is-horizontal"

        >
            <div className="label is-size-6">{fieldLabel}</div>
            <a
                rel="noopener noreferrer"
                target="_blank"
                className="value margin-left-sm is-size-6"
                href={`${detailLinkURL}/${linkId}`}
                data-for={'tooltipLink'}
                data-tip={'Opens in New Tab'}
            >
                {formatValue(fieldValue)}
                <Tooltip id={'tooltipLink'}/>
            </a>
        </div>
    )
}
export default withConfig(({ tooltipData, tooltipFilterData, config }) => {
    const { borderClass } = useContext(PanelStyleContext)
    const { ID_COLUMN, DETAIL_LINK_URL, LINK_COLUMN } = config
    const tooltipDetailProps = {
        formFields: tooltipFilterData,
        data: tooltipData,
        fieldGroups: [
            ...new Set(tooltipFilterData.map(x => x.GroupName)),
        ].filter(x => x !== null),
    }

    const tooltipDetailData = mapDataToLabels(tooltipDetailProps)
    return (
        <div className={`tooltipDataWrapper ${borderClass}`}>
            <div className="columns is-multiline">
                <div className="column is-full">
                    <WellLinkComponent
                        {...tooltipDetailData[ID_COLUMN]}
                        linkId={tooltipData[ID_COLUMN]}
                        detailLinkURL={DETAIL_LINK_URL}
                    />
                </div>
                {tooltipDetailProps.fieldGroups.map((groupName, idx) => {
                    const associatedColumns = tooltipDetailProps.formFields.filter(x => x.GroupName === groupName).map(x => x.ControlName)
                    const associatedData = Object.keys(tooltipDetailProps.data).filter(x => associatedColumns.includes(x)).reduce((acc, cur) => ({...acc, [cur]: tooltipDetailProps.data[cur]}), {})
                    return (
                        <React.Fragment key={idx}>
                            <div className="column is-full">
                                <div className="label is-size-6">
                                    {groupName}
                                </div>
                            </div>
                            {Object.keys(associatedData).map((fieldName, idx) => {
                                const associatedFieldData = tooltipDetailProps.formFields.find(x => x.ColumnName === fieldName)
                                const fieldLabel = associatedFieldData.Prompt
                                const fieldValue = associatedData[fieldName]
                                // if (typeof fieldValue == 'undefined') {
                                if (fieldValue === null || typeof fieldValue === 'undefined') {
                                    return null
                                }
                                return (
                                    <div className={`${fieldName === LINK_COLUMN ? "column is-full" : "column is-half"}`} key={idx}>
                                        <FieldComponent fieldLabel={fieldLabel} fieldValue={fieldValue} size={7} />
                                    </div>
                            )})}
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    )
})
