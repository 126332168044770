const getInputFilterParams = data => {
    const inputKeys = Object.keys(data).filter(name => name.includes('Value'))
    const filterParamsObject = inputKeys.reduce((acc, currentValue) => {
        const filterParameter = currentValue
        const comparisonOperatorParameter = `${
            currentValue.split('Value')[0]
        }Operator`

        // if the form value is a string,
        // then the parameter values will be that string
        if (typeof data[filterParameter] == 'string' && data[filterParameter]) {
            const filterValue = encodeURIComponent(data[filterParameter])
            const comparisonOperatorValue = data[comparisonOperatorParameter]
                ? encodeURIComponent(data[comparisonOperatorParameter])
                : null
            return {
                ...acc,
                [filterParameter]: filterValue,
                [comparisonOperatorParameter]: comparisonOperatorValue,
            }
        }

        // if the form value is an array,
        // join the values with a comma
        if (
            data[filterParameter] &&
            typeof data[filterParameter] == 'object' &&
            data[filterParameter].length
        ) {
            const nonNullIndexes = data[filterParameter].map((param, idx) =>
                param !== '' && param !== null ? idx : null
            )
            const applicableFilterValues = data[filterParameter]
                .filter((param, idx) => nonNullIndexes.indexOf(idx) > -1)
                .join(',')
            const applicableOperatorValues = data[comparisonOperatorParameter]
                .filter((param, idx) => nonNullIndexes.indexOf(idx) > -1)
                .join(',')
            if (applicableFilterValues) {
                return {
                    ...acc,
                    [filterParameter]: encodeURIComponent(
                        applicableFilterValues
                    ),
                    [comparisonOperatorParameter]: encodeURIComponent(
                        applicableOperatorValues
                    ),
                }
            }
        }
        return acc
    }, {})
    return filterParamsObject
}

const getSelectFilterParams = data => {
    const selectKeys = Object.keys(data).filter(name => name.includes('Select'))
    return selectKeys.reduce((acc, currentValue) => {
        const controlName = currentValue.split('Select')[0]
        const filterParameter = `${controlName}Value`
        const comparisonOperatorParameter = `${
            currentValue.split('Select')[0]
        }Operator`

        // exit early if data this select is undefined
        if (!data[currentValue] || !data[currentValue].value) {
            return acc
        }
        const filterValue = encodeURIComponent(data[currentValue].value)
        const comparisonOperatorValue = encodeURIComponent(
            data[comparisonOperatorParameter]
                ? data[comparisonOperatorParameter]
                : '='
        )
        return {
            ...acc,
            [filterParameter]: filterValue,
            [comparisonOperatorParameter]: comparisonOperatorValue,
        }
    }, {})
}

const getMultiSelectFilterParams = data => {
    const selectKeys = Object.keys(data).filter(name => name.includes('Multi'))
    return selectKeys.reduce((acc, currentValue) => {
        const controlName = currentValue.split('Multi')[0]
        const filterParameter = `${controlName}Value`
        const comparisonOperatorParameter = `${
            currentValue.split('Multi')[0]
        }Operator`

        if (!data[currentValue]) {
            return acc
        }

        const filterValue = encodeURIComponent(
            data[currentValue]
                .map(selectedOption => selectedOption.value)
                .join(',')
        )
        const comparisonOperatorValue = encodeURIComponent(
            data[currentValue]
                .map(x =>
                    data[comparisonOperatorParameter]
                        ? data[comparisonOperatorParameter]
                        : '='
                )
                .join(',')
        )
        return {
            ...acc,
            [filterParameter]: filterValue,
            [comparisonOperatorParameter]: comparisonOperatorValue,
        }
    }, {})
}

const getCheckboxFilterParams = data => {
    const checkKeys = Object.keys(data).filter(name =>
        name.includes('Checkbox')
    )
    return checkKeys.reduce((acc, currentValue) => {
        const controlName = currentValue.split('Checkbox')[0]
        const filterParameter = `${controlName}Value`
        const comparisonOperatorParameter = `${controlName}Operator`

        // exit early if data this checkbox is falsy
        if (!data[currentValue]) {
            return acc
        }
        return {
            ...acc,
            [filterParameter]: 1,
            [comparisonOperatorParameter]: '=',
        }
    }, {})
}

export default data => {
    const inputFilterParams = getInputFilterParams(data)
    const selectFilterParams = getSelectFilterParams(data)
    const multiSelectFilterParams = getMultiSelectFilterParams(data)
    const checkboxFilterParams = getCheckboxFilterParams(data)

    return {
        ...inputFilterParams,
        ...selectFilterParams,
        ...multiSelectFilterParams,
        ...checkboxFilterParams,
    }
}
