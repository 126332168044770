export default (getValues, setValue) => {
    const values = getValues()
    // we have to manually trigger reset on react-select components
    // reset multiselect components
    Object.keys(values)
        .filter(x => x.includes('Multi'))
        .map(key => setValue(key, null))

    // reset select components
    Object.keys(values)
        .filter(x => x.includes('Select'))
        .map(key => setValue(key, null))

    // reset date components
    Object.keys(values)
        .filter(x => x.includes('Date'))
        .map(key => setValue(key, null))

    // reset checkbox components
    Object.keys(values)
        .filter(x => x.includes('Checkbox'))
        .map(key => setValue(key, false))

    // also clear the field array values
    Object.keys(values)
        .filter(x => x.includes('['))
        .map(key => setValue(key, null))

}
