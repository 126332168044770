import { Image as ImageLayer } from 'ol/layer'
import { ImageArcGISRest, XYZ } from 'ol/source'
import EsriJSON from 'ol/format/EsriJSON'
import { Vector as VectorLayer, Tile as TileLayer } from 'ol/layer'
import { tile as tileStrategy } from 'ol/loadingstrategy'
import GeoJSON from 'ol/format/GeoJSON'
import VectorSource from 'ol/source/Vector'
import { createXYZ } from 'ol/tilegrid'
import { Style, Fill, Stroke, Text, Circle, Icon} from 'ol/style'
import toast from '../../../../elem/Toast'
import styleFunction from '../mapStyles'

const formatLabel = (labelData, feature) => {
    // label data comes in like "<field_one>;'<string_one>';<field_two>"
    const labelParts = labelData.split(';').reduce((acc, curr) => {
        let value = ''
        if (curr.includes("'")) {
            value = curr.replace("'", '')
        } else {
            value = feature.get(curr)
        }
        return [...acc, value]
    }, [])
    return labelParts.join(' ')
}

const canvasPatterns = {}
const getThemeFeatureFill = async color => {
    if (color && (color.includes('.png') || color.includes('.svg'))) {
        if (canvasPatterns[color]) {
            return Promise.resolve(canvasPatterns[color])
        } else {
            const cnv = document.createElement('canvas')
            const ctx = cnv.getContext('2d')
            const img = new Image()
            img.src = color
            return new Promise((resolve, reject) => {
                img.onload = () => {
                    const pattern = ctx.createPattern(img, 'repeat')
                    canvasPatterns[color] = pattern
                    return resolve(pattern)
                }
            })
        }
    } else {
        return Promise.resolve(color)
    }
}

const getColorMapFeatureColor = (value, styleObj) => {
    let color = 'red'
    styleObj.forEach(bin => {
        const { label, fillColor } = bin
        if (value === label) {
            color = fillColor
        }
        return null
    })
    if (color === 'red') {
        toast({level: 'error', message: 'label unknown: ' + value})
    }
    return color
}

const getSpectrumFeatureProps = (value, styleObj) => {
    let color = 'rgba(255,255,255, 0)'
    let valueLabel = ''
    styleObj.forEach(bin => {
        const { minValue, maxValue, fillColor, label } = bin
        if (maxValue) {
            if (Number(value) > minValue && Number(value) < maxValue) {
                color = fillColor
                valueLabel = label
            }
        } else {
            if (Number(value) > minValue) {
                color = fillColor
                valueLabel = label
            }
        }
        return null
    })
    if (valueLabel === '' && value !== '') {
        toast({level: 'error', message: 'Unable to get value label for spectrum feature value: ' + value})
    }
    return { color, label: valueLabel }
}

const getLayerState = (layerData, existingLayerState, defaultDisplayLayers) => {
    let layerState = existingLayerState ? existingLayerState : {}
    if (layerData) {
        Object.keys(layerData).forEach(key => {
            const layers = layerData[key]
            layers.forEach(layer => {
                const layerName = layer.LayerName
                const layerGroupName = layer.LayerType === 'THEME' ? layer.LayerGroupName : 'Basemap Layers'
                const display = defaultDisplayLayers.includes(layerName)
                const unique = layer.LayerType === 'THEME' ? layer.Unique : false
                layerState = {
                    ...layerState,
                    [layerName]: {
                        display,
                        expanded: false,
                        unique,
                        layerGroupName,
                        ...layer,
                    },
                }
            })
        })
    }
    return layerState
}

const errorToast = (response, name) => {
    toast({ level: 'error', message: 
    `Failed to fetch layer ${name}: ` + 
        response.error.message +
            '\n' +
            response.error.details.join('\n')
    })
}

export default async (layer, API_URL, cacheFetch) => {
    const {
        LayerType: type,
        SourceUrl: url,
        Label: labelData,
        StyleObj: styles,
        MinZoom,
        MaxZoom,
        Seq: zIndex,
        LayerName: layerName
    } = layer
    const style = JSON.parse(styles)
    const minZoom = typeof MinZoom == 'number' ? MinZoom : -Infinity
    const maxZoom = typeof MaxZoom == 'number' ? MaxZoom : Infinity
    if (type === 'FEATURE') {
        const esrijsonFormat = new EsriJSON()
        let vectorSource = new VectorSource({
            loader: function(extent, resolution, projection) {
                const requestUrl =
                    url +
                    '/query/?f=json&' +
                    'returnGeometry=true&geometryType=esriGeometryEnvelope&spatialRel=esriSpatialRelIntersects&geometry=' +
                    encodeURIComponent(
                        // '{"xmin":' +
                        extent[0] +
                            ',' +
                            // '"ymin":' +
                            extent[1] +
                            ',' +
                            // '"xmax":' +
                            extent[2] +
                            ',' +
                            // '"ymax":' +
                            extent[3] +
                            ''
                        // ',"spatialReference":{"wkid":3857}}'
                    ) +
                    '&inSR=3857&outFields=*' +
                    '&outSR=3857&where=1%3D1' +
                    '&resultType=tile' // increases max record count to 32000
                fetch(requestUrl)
                    .then(async response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            const error = await response.text()
                            throw new Error(error)
                        }
                    })
                    .then(response => {
                        if (response.error) {
                            errorToast(response, layerName)
                        } else {
                            // dataProjection will be read from document
                            var features = esrijsonFormat.readFeatures(
                                response,
                                {
                                    featureProjection: projection,
                                }
                            )
                            if (features.length > 0) {
                                const currentFeatureIds = vectorSource
                                    .getFeatures()
                                    .map(f => f.get('OBJECTID'))
                                const uniqueFeatures = features.filter(
                                    x =>
                                        !currentFeatureIds.find(
                                            y => y === x.get('OBJECTID')
                                        )
                                )
                                vectorSource.addFeatures(uniqueFeatures)
                            }
                        }
                    })
            },
            strategy: tileStrategy(
                createXYZ({
                    tileSize: 512,
                })
            ),
        })
        const layer = new VectorLayer({
            source: vectorSource,
            visible: false,
            minZoom,
            maxZoom,
            zIndex,
            style: feature => {
                if (feature.getGeometry().getType() === 'Point') {
                    return new Style({
                        image: new Circle({
                            radius: 3,
                            fill: new Fill({
                                color: style.fillColor,
                            }),
                            stroke: new Stroke({
                                color: style.strokeColor,
                                width: 0.5,
                            }),
                        }),
                        text: new Text({
                            text: formatLabel(labelData, feature),
                            font: '11px sans-serif',
                            fill: new Fill({
                                color: style.textColor
                                    ? style.textColor
                                    : 'white',
                            }),
                            stroke: new Stroke({
                                color: style.textColor
                                    ? style.textColor
                                    : 'black',
                                width: 0.5,
                            }),
                            offsetX: 2,
                            offsetY: -10,
                        }),
                    })
                } else {
                    return new Style({
                        fill: new Fill({
                            color: style.fillColor,
                        }),
                        stroke: new Stroke({
                            color: style.strokeColor,
                            width: style.strokeWidth ? style.strokeWidth : 0.5,
                            lineDash: style.strokeDash ? style.strokeDash : null
                        }),
                        text: new Text({
                            text: formatLabel(labelData, feature),
                            font: '11px sans-serif',
                            fill: new Fill({
                                color: style.textColor
                                    ? style.textColor
                                    : 'white',
                            }),
                            stroke: new Stroke({
                                color: style.textColor
                                    ? style.textColor
                                    : 'black',
                                width: 0.5,
                            }),
                        }),
                    })
                }
            },
        })
        return layer
    } else if (type === 'IMAGE') {
        return new ImageLayer({
            source: new ImageArcGISRest({
                ratio: 1,
                params: style && style.params,
                url: url,
            }),
            visible: false,
            opacity: style && style.opacity ? style.opacity : 0.55,
            zIndex,
        })
    } else if (type === 'RASTER') {
        return new TileLayer({
            source: new XYZ({
                url: `${url}/tile/{z}/{y}/{x}`
            }),
            
            visible: false,
            opacity: style && style.opacity ? style.opacity : 0.55,
            zIndex,
        })
    } else if (type === 'THEME') {
        const {
            SourceFile: sourceFile,
            DataUrl: dataUrl,
            JoinColumn: joinColumn,
            DefaultFill: defaultFill,
        } = layer
        // fetch the source file content
        return await cacheFetch(`/${sourceFile}`, null, `themeLayer:${sourceFile}`, 1)
            .then(async data => {
                // fetch the api data
                return await fetch(`${API_URL}/${dataUrl}`)
                    .then(async response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            const error = await response.text()
                            throw new Error(error)
                        }
                    })
                    .then(async response => {
                        const apiData = response.data
                        // read source features
                        const features = new GeoJSON().readFeatures(data)

                        // since some of the theme 'colors' are images (eg stripes)
                        // we need to use promises to collect all of the images asynchronously
                        // and add them as the color property of the feature
                        await Promise.all(
                            features.map(async x => {
                                x.getGeometry().transform(
                                    'EPSG:4326',
                                    'EPSG:3857'
                                )
                                const row = apiData.find(
                                    y => y[joinColumn] === x.get(joinColumn)
                                )
                                if (row) {
                                    // async fetch of pattern images if necessary
                                    const color = await getThemeFeatureFill(
                                        row['Color']
                                    )
                                    x.set('color', color)
                                    x.set('label', row["Label"])
                                } else {
                                    x.set(
                                        'color',
                                        defaultFill
                                        ? defaultFill
                                        : 'rgba(255, 255, 255, 0)'
                                        )
                                        x.set('label', '')
                                    }
                                return Promise.resolve(x)
                            })
                        )
                        const source = new VectorSource({
                            features,
                        })
                        const vectorLayer = new VectorLayer({
                            source: source,
                            minZoom,
                            maxZoom,
                            visible: false,
                            zIndex,
                            style: styleFunction.bind(this, true)
                        })
                        return vectorLayer
                    })
                    .catch(e => {
                        toast({
                            level: 'error',
                            message:
                                'Well Map: ' +
                                (e.message
                                    ? e.message
                                    : 'Unable to connect to the server. Please try again later.'),
                        })
                    })
            })
    } else if (type === 'COLORMAP') {
        const esrijsonFormat = new EsriJSON()
        let vectorSource = new VectorSource({
            loader: function(extent, resolution, projection) {
                const requestUrl =
                    url +
                    '/query/?f=json&' +
                    'returnGeometry=true&geometryType=esriGeometryEnvelope&spatialRel=esriSpatialRelIntersects&geometry=' +
                    encodeURIComponent(
                        // '{"xmin":' +
                        extent[0] +
                            ',' +
                            // '"ymin":' +
                            extent[1] +
                            ',' +
                            // '"xmax":' +
                            extent[2] +
                            ',' +
                            // '"ymax":' +
                            extent[3] +
                            ''
                        // ',"spatialReference":{"wkid":3857}}'
                    ) +
                    '&inSR=3857&outFields=*' +
                    '&outSR=3857&where=1%3D1' +
                    '&resultType=tile' // increases max record count to 32000
                fetch(requestUrl)
                    .then(async response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            const error = await response.text()
                            throw new Error(error)
                        }
                    })
                    .then(response => {
                        if (response.error) {
                            errorToast(response, layerName)
                        } else {
                            // dataProjection will be read from document
                            var features = esrijsonFormat.readFeatures(
                                response,
                                {
                                    featureProjection: projection,
                                }
                            )
                            const styleObj = JSON.parse(styles)

                            features.forEach(feature => {
                                const color = getColorMapFeatureColor(
                                    formatLabel(labelData, feature),
                                    styleObj
                                )
                                feature.set('color', color)
                            })
                            if (features.length > 0) {
                                const currentFeatureIds = vectorSource
                                    .getFeatures()
                                    .map(f => f.get('OBJECTID'))
                                const uniqueFeatures = features.filter(
                                    x =>
                                        !currentFeatureIds.find(
                                            y => y === x.get('OBJECTID')
                                        )
                                )
                                vectorSource.addFeatures(uniqueFeatures)
                            }
                        }
                    })
            },
            strategy: tileStrategy(
                createXYZ({
                    tileSize: 512,
                })
            ),
        })
        const layer = new VectorLayer({
            source: vectorSource,
            visible: false,
            minZoom,
            maxZoom,
            zIndex,
            opacity: 0.65,
            style: feature =>
                new Style({
                    fill: new Fill({
                        color: feature.get('color'),
                    }),
                    stroke: new Stroke({
                        color: 'grey',
                        width: 0.5,
                    }),
                    // text: new Text({
                    //     text: formatLabel(labelData, feature),
                    //     fill: new Fill({ color: 'black' }),
                    //     stroke: new Stroke({ color: 'black', width: 0.5 }),
                    // }),
                }),
        })
        return layer
    } else if (type === 'SPECTRUM') {
        const esrijsonFormat = new EsriJSON()
        let vectorSource = new VectorSource({
            loader: function(extent, resolution, projection) {
                const requestUrl =
                    url +
                    '/query/?f=json&' +
                    'returnGeometry=true&geometryType=esriGeometryEnvelope&spatialRel=esriSpatialRelIntersects&geometry=' +
                    encodeURIComponent(
                        // '{"xmin":' +
                        extent[0] +
                            ',' +
                            // '"ymin":' +
                            extent[1] +
                            ',' +
                            // '"xmax":' +
                            extent[2] +
                            ',' +
                            // '"ymax":' +
                            extent[3] +
                            ''
                        // ',"spatialReference":{"wkid":3857}}'
                    ) +
                    '&inSR=3857&outFields=*' +
                    '&outSR=3857&where=1%3D1' +
                    '&resultType=tile' // increases max record count to 32000
                fetch(requestUrl)
                    .then(async response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            const error = await response.text()
                            throw new Error(error)
                        }
                    })
                    .then(response => {
                        if (response.error) {
                            errorToast(response, layerName)
                        } else {
                            // dataProjection will be read from document
                            var features = esrijsonFormat.readFeatures(
                                response,
                                {
                                    featureProjection: projection,
                                }
                            )
                            const styleObj = JSON.parse(styles)

                            features.forEach(feature => {
                                const {
                                    color,
                                    label,
                                } = getSpectrumFeatureProps(
                                    formatLabel(labelData, feature),
                                    styleObj
                                )
                                feature.set('color', color)
                                feature.set('label', label)
                            })
                            if (features.length > 0) {
                                const currentFeatureIds = vectorSource
                                    .getFeatures()
                                    .map(f => f.get('OBJECTID'))
                                const uniqueFeatures = features.filter(
                                    x =>
                                        !currentFeatureIds.find(
                                            y => y === x.get('OBJECTID')
                                        )
                                )
                                vectorSource.addFeatures(uniqueFeatures)
                            }
                        }
                    })
            },
            strategy: tileStrategy(
                createXYZ({
                    tileSize: 512,
                })
            ),
        })
        const layer = new VectorLayer({
            source: vectorSource,
            visible: false,
            minZoom,
            maxZoom,
            zIndex,
            opacity: 0.65,
            style: feature =>
                new Style({
                    fill: new Fill({
                        color: feature.get('color'),
                    }),
                    stroke: new Stroke({
                        color: 'grey',
                        width: 0.5,
                    }),
                    // text: new Text({
                    //     text: feature.get('label'),
                    //     fill: new Fill({ color: 'black' }),
                    //     stroke: new Stroke({ color: 'black', width: 0.5 }),
                    // }),
                }),
        })
        return layer
    } else if (type === 'SYMBOLIZED') {
        const symbol = await new Promise(resolve => {
            const loadImage = new Image()
            loadImage.src = style.imgSrc
            loadImage.onload = () => {
                resolve(loadImage)
            }
        })
        if (symbol) {
            const esrijsonFormat = new EsriJSON()
            let vectorSource = new VectorSource({
                loader: function(extent, resolution, projection) {
                    const requestUrl =
                        url +
                        '/query/?f=json&' +
                        'returnGeometry=true&geometryType=esriGeometryEnvelope&spatialRel=esriSpatialRelIntersects&geometry=' +
                        encodeURIComponent(
                            // '{"xmin":' +
                                extent[0] +
                                ',' + 
                                // '"ymin":' +
                                extent[1] +
                                ',' + 
                                // '"xmax":' +
                                extent[2] +
                                ',' + 
                                // '"ymax":' +
                                extent[3] +
                                ''
                                // ',"spatialReference":{"wkid":3857}}'
                        ) +
                        '&inSR=3857&outFields=*' +
                        '&outSR=3857&where=1%3D1' +
                        '&resultType=tile' // increases max record count to 32000
                    fetch(requestUrl).then(async response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            const error = await response.text()
                            throw new Error(error)
                        }
                    }).then(response => {
                        if (response.error) {
                            errorToast(response, layerName)
                        } else {
                            // dataProjection will be read from document
                            var features = esrijsonFormat.readFeatures(
                                response,
                                {
                                    featureProjection: projection,
                                }
                            )
                            if (features.length > 0) {
                                vectorSource.addFeatures(features)
                            }
                        }
                    })
                },
                strategy: tileStrategy(
                    createXYZ({
                        tileSize: 512,
                    })
                ),
            })
            const layer = new VectorLayer({
                source: vectorSource,
                visible: false,
                minZoom,
                maxZoom,
                zIndex,
                style: feature => {
                    if (feature.getGeometry().getType() === 'Point') {
                        return new Style({
                            image: new Icon({
                                img: symbol,
                                imgSize: '30px',
                                scale: 1,
                                size: [20, 20],
                            }),
                            text: new Text({
                                text: formatLabel(labelData, feature),
                                fill: new Fill({color: style.textColor ? style.textColor : 'white'}),
                                stroke: new Stroke({color: style.textColor ? style.textColor : 'black'}),
                                offsetX: 2,
                                offsetY: -15
                            })
                        })
                    } else {
                        return new Style({
                        fill: new Fill({
                            color: style.fillColor
                        }),
                        stroke: new Stroke({
                            color: style.strokeColor,
                            width: style.strokeWidth ? style.strokeWidth : 0.5,
                        }),
                        text: new Text({
                            text: formatLabel(labelData, feature),
                            fill: new Fill({color: style.textColor ? style.textColor : 'white'}),
                            stroke: new Stroke({color: style.textColor ? style.textColor : 'black'}),
                        })
                    })
                }
            }})
            return layer
        } else {
            return null
        }
    }
}

export { getLayerState }
