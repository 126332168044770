import React from 'react'

import WellExplorer from './wells/Explorer'
import Map from './map/Map'

export default () => (
    <div className="spaWrapper">
        <div className="columns spaContainer is-full is-multiline is-1 is-variable">
            <div className="column is-half-desktop is-full-tablet is-full-mobile">
                <Map width={'full'} />  
            </div>
            <div className="column is-half-desktop is-full-tablet is-full-mobile">
                <WellExplorer width={'full'} />
            </div>
        </div>
    </div>
)
