import React from 'react'

import PanelStyleContextProvider, {
    PanelStyleContext,
} from './PanelStyleContext'

export default ({ panelClassConfig, children }) => (
    <PanelStyleContextProvider panelClassConfig={panelClassConfig}>
        <PanelStyleContext.Consumer>
            {value => (
                <div
                    className={`dashboardPanel ${
                        value.panelClassName ? value.panelClassName : ''
                    }`}
                >
                    <div className={`dashboardPanelShadow`}>
                        <div className={`dashboardPanelBody`}>{children}</div>
                    </div>
                </div>
            )}
        </PanelStyleContext.Consumer>
    </PanelStyleContextProvider>
)
