import React, { useContext } from 'react'
import { Controller } from 'react-hook-form'
import ReactSelect from 'react-select'
import { FaInfoCircle } from 'react-icons/fa'

import Tooltip from '../Tooltip'
import SelectStyles from './SelectStyles'
import { ParameterContext } from '../../wrappers/ParameterContext'
import { PanelStyleContext } from '../panel/PanelStyleContext'


const SelectAllComponent = props => {
    if (props.allowSelectAll) {
        const allOption = {
            label: "Select All",
            value: "*",
            active: true
        }
        return (
            <ReactSelect
              {...props}
              options={[allOption, ...props.options]}
              isOptionDisabled={option =>
                !option.active
              }
              onChange={selected => {
                if (
                  selected !== null &&
                  selected.length > 0 &&
                  selected[selected.length - 1].value === allOption.value
                ) {
                  return props.onChange(props.options.filter(x => x.active));
                }
                return props.onChange(selected);
              }}
            />
          );
    }
    return <ReactSelect {...props} />
}
const mapValuesToOptions = (values, fieldAccessorMap) => {
    const options = values.map(v => {
        return {
            label: v[fieldAccessorMap.label],
            value: v[fieldAccessorMap.value],
            active: v[fieldAccessorMap.active],
        }
    })
    return options
}

const getInitialValues = (name, formName, params, options) => {
    const filterName = `${name}Value`
    const initialFilterValues = params[formName] && params[formName][filterName]
        ? decodeURIComponent(params[formName][filterName]).split(',')
        : []

    const initialValues = initialFilterValues.map(x =>
        options.find(y => y.value === x.toString() || y.value === x || y.value === parseInt(x))
        )
    return initialValues
}

const MultiSelect = ({
    name,
    fieldName,
    comparisonOperators,
    values,
    helper,
    control,
    fieldAccessorMap,
    formWidth,
    formName,
    register,
    registerParams
}) => {
    const { params } = useContext(ParameterContext)
    const { selectDropDownCaratClass, tooltipClass } = useContext(PanelStyleContext)
    const options = mapValuesToOptions(values, fieldAccessorMap)
    const initialValues = getInitialValues(name, formName, params, options)
    return (
        <div className={`column ${formWidth === 'full' ? 'is-one-third' : 'is-full'} no-vertical-padding formInputWrapper`}>
            <div className="formInputLayout">
            <input 
                className="is-hidden" 
                name={`${name}Operator`} 
                value={comparisonOperators ? comparisonOperators[0] : '='}
                ref={register(registerParams)}
                readOnly={true}
            />
                <div className="field">
                    <div className="field-label is-small">
                        <label className="label">{fieldName}</label>
                    </div>
                    <div className="field-body">
                        <div className={`field ${helper ? 'has-addons' : ''}`}>
                            {helper && (
                                <div 
                                data-tip={`${helper}`} 
                                data-for={name}
                                className="control formHelper"
                                >
                                    <span
                                        
                                        className="button is-static is-small"
                                    >
                                        <FaInfoCircle />
                                        <Tooltip id={name} className={tooltipClass} /> 
                                    </span>
                                </div>
                            )}
                            <div className={`control is-expanded ${selectDropDownCaratClass}`}>
                                <Controller
                                    as={
                                        <SelectAllComponent
                                            isMulti
                                            allowSelectAll={true}
                                            isClearable
                                            className="select is-small is-multiple is-fullwidth reactSelect"
                                            classNamePrefix="reactSelect"
                                            menuPlacement="auto"
                                            options={options}
                                            styles={SelectStyles}
                                            placeholder={"Select Multiple..."}
                                            closeMenuOnSelect={false}
                                        />
                                    }
                                    name={`${name}Multi`}
                                    control={control}
                                    onChange={([selected]) => {
                                        const value = selected && selected.length ? selected : null 
                                        // React Select return object instead of value for selection
                                        return { value }
                                    }}
                                    defaultValue={
                                        initialValues.length
                                            ? initialValues
                                            : null
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MultiSelect
