import React, { useContext } from 'react'
import { IconContext } from 'react-icons'
import { FaSpinner } from 'react-icons/fa'

import { PanelStyleContext } from '../../../../elem/panel/PanelStyleContext'

export default () => {
    const { borderClass, colorClass } = useContext(PanelStyleContext)
    return (
        <div className={`tooltipLoading ${borderClass} ${colorClass}`}>
            <IconContext.Provider
                value={{
                    className: 'fa-pulse tooltipLoadingIcon',
                    size: '1.5rem',
                    style: {
                        marginRight: 10,
                    },
                }}
            >
                <FaSpinner />
            </IconContext.Provider>
        </div>
    )
}
