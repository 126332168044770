import React, {
    createContext,
    useState,
    useEffect,
    useMemo,
    useContext,
    useRef,
} from 'react'
import dayjs from 'dayjs'
import localForage from 'localforage'
import { debounce } from 'debounce'

import withConfig from '../../../wrappers/withConfig'
import { ParameterContext } from '../../../wrappers/ParameterContext'
import { AppStateContext } from '../AppStateContext'
import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import { UserContext } from '../../../wrappers/UserContext'

import { getQueryStringFromParams } from '../../../../utils/params'
import toast from '../../../elem/Toast'
import { getRoles, hasAccessToPWS } from '../../../../utils/user/permissions'

const DataContext = createContext(null)

const DataContextProvider = ({ config, children }) => {
    const [allData, setAllData] = useState({})
    const [filteredData, setFilteredData] = useState([])
    const [layerData, setLayerData] = useState([])
    const filterRef = useRef([])
    const [loading, setLoading] = useState(true)
    const [tooltipLoading, setTooltipLoading] = useState(true)
    const { API_URL, ID_COLUMN } = config

    const { params, setParams } = useContext(ParameterContext)
    const { setMapState } = useContext(AppStateContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { client, user } = useContext(UserContext)

    const [tooltipData, setTooltipData] = useState({})
    const [tooltipFilterData, setTooltipFilterData] = useState({})

    // load all map data from cache first, network request second
    useMemo(() => {
        setLoading(true)
        client.getUser().then(user => {
            const roles = getRoles(user)
            const isAgency = hasAccessToPWS(roles)
            const storageItemName = `mapData:${isAgency ? 'agency': 'public'}`
            localForage.getItem(storageItemName).then(cachedData => {
                if (cachedData) {
                    const json = JSON.parse(cachedData)
                    if (json && json.data && json.expires) {
                        if (dayjs() < dayjs(json.expires)) {
                            setLoading(false)
                            return setAllData(json.data)
                        }
                    }
                }
                authenticatedFetch(`${API_URL}/well/map`)
                    .then(async response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            const error = await response.text()
                            throw new Error(error)
                        }
                    })
                    .then(response => {
                        const sessionObject = {
                            expires: dayjs().add(1, 'day'),
                            data: response.data,
                        }
                        localForage
                            .setItem(storageItemName, JSON.stringify(sessionObject))
                            .then(() => {
                                setAllData(response.data)
                            })
                            .catch(e => {
                                toast({
                                    level: 'info',
                                    message:
                                        'Map: Unable to store data locally. This will effect map loading times.',
                                })
                            })
                    })
                    .catch(e => {
                        toast({
                            level: 'error',
                            message:
                                'Well Map: ' +
                                (e.message
                                    ? e.message
                                    : 'Unable to connect to the server. Please try again later.'),
                        })
                    })
                    .finally(() => setLoading(false))
            })
        })
        .catch(() => {})
    }, [user])

    // load map layer data
    useEffect(() => {
        authenticatedFetch(`${API_URL}/map/layers`)
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setLayerData(response)
            })
            .catch(e => {
                toast({
                    level: 'error',
                    message:
                        'Map Layers:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                })
            })
    }, [API_URL])

    const filterData = params => {
        if (allData.features) {
            const parameterString = getQueryStringFromParams(params, true)
            if (parameterString) {
                authenticatedFetch(`${API_URL}/well/map?${parameterString}`)
                    .then(async response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            const error = await response.text()
                            throw new Error(error)
                        }
                    })
                    .then(response => {
                        const d = response.data.features.map(
                            x => x.properties[ID_COLUMN]
                        )
                        setFilteredData(d)
                        filterRef.current = d
                    })
                    .catch(e => {
                        toast({
                            level: 'error',
                            message:
                                'Map: ' +
                                (e.message
                                    ? e.message
                                    : 'Unable to connect to the server. Please try again later.'),
                        })
                    })
            } else {
                const d = allData.features.map(
                    x => x.properties[ID_COLUMN]
                )
                setFilteredData(d)
            }
        }
    }

    const fetchTooltipDataDebounce = debounce(
        id => {
            authenticatedFetch(`${API_URL}/well/tooltip/${id}`)
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    setTooltipData(response.data)
                    setTooltipFilterData(response.filterData)
                    setTooltipLoading(false)
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message:
                            'Well Map: ' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.'),
                    })
                })
        },
        300,
        false
    )

    const fetchTooltipData = id => {
        setTooltipLoading(true)
        fetchTooltipDataDebounce.clear()
        fetchTooltipDataDebounce(id)
    }

    const updateFilteredData = newData => {
        const f = filterRef.current
        const updatedData = [...f, ...newData]
        filterRef.current = updatedData
        setFilteredData(updatedData)
        setMapState(prevMapState => ({
            ...prevMapState,
            filteredData: updatedData,
        }))
    }

    const clearSelectionLayer = () => {
        setMapState(mapState => ({ ...mapState, selectedFeatures: [] }))
    }

    // fetch data on parameter changes
    useEffect(() => {
        filterData(params)
    }, [params['well'], allData])

    return (
        <DataContext.Provider
            value={{
                data: allData,
                filteredData,
                layerData,
                tooltipData,
                tooltipFilterData,
                tooltipLoading,
                fetchTooltipData,
                setFilteredData,
                updateFilteredData,
                clearSelectionLayer,
                params,
                setParams,
                loading,
            }}
        >
            {children}
        </DataContext.Provider>
    )
}

export { DataContext }
export default withConfig(DataContextProvider)
