import React, { 
    useState, 
} from 'react'
import { useLocation } from 'react-router-dom'
import { 
    FaSyncAlt
} from 'react-icons/fa'

import { isPageExplorer } from '../../../utils/constants/urls'
import Burger from './Burger'
import withConfig from '../../wrappers/withConfig'
import {
    AlertButton,
    DisclaimerButton,
} from './InformationalButtons'

const Navbar = ({ config }) => {
    const [burgerExpanded, toggleBurger] = useState(false)
    const location = useLocation()
    const { APP_BANNER_TITLE, ALERT_TEXT, DISCLAIMER_TEXT, APP_NAVBAR_INFO } = config
    const isExplorer = isPageExplorer(location)
    
    return (
        <nav
            className="navbar is-fixed-top is-transparent has-shadow is-boxed"
            role="navigation"
            aria-label="main navigation"
        >
            <div className="navbar-brand">
                <div className="app-title mobile">{APP_BANNER_TITLE}</div>
                <Burger expanded={burgerExpanded} onClick={toggleBurger} />
            </div>
            <div
                id="navbarBasicExample"
                className={`navbar-menu ${burgerExpanded ? 'is-active' : ''}`}
            >
                <div className="navbar-start">
                    <div className="navbar-item">
                        <a target="_blank" rel="noopener noreferrer" href={config.HOME_PAGE_LINK}>{config.HOME_PAGE_TITLE}</a>
                    </div>
                    <div className="navbar-item app-title desktop">
                        {APP_BANNER_TITLE}
                    </div>
                    <div
                        className="navbar-item production-warning desktop"
                    >
                        {APP_NAVBAR_INFO}
                    </div>
                </div>

                <div className="navbar-end">
                    {isExplorer && <>
                        <div className="navbar-item">
                            <div className="buttons">
                                {ALERT_TEXT && <AlertButton text={ALERT_TEXT} />}
                                <DisclaimerButton text={DISCLAIMER_TEXT} />
                            </div>
                        </div>
                        <div className="navbar-item">
                            <div className="buttons">
                                <button className="button is-info is-small globalResetButton">
                                    <a href={location.pathname}>
                                        <FaSyncAlt />
                                        Reset All Filters
                                    </a>
                                </button>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </nav>
    )
}

export default withConfig(Navbar)
