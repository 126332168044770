import React, { useEffect, useState, createContext, useContext, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import withConfig from '../../../wrappers/withConfig'
import { DataContext } from '../DataContext'
import { UserContext } from '../../../wrappers/UserContext'
import actions from '../../../../utils/submissions/actions'

const ExistingDataContext = createContext(null)

const ExistingDataContextProvider = ({ children, config }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { setActiveAgency, initializeState, submissionState, setFacilityID } = useContext(DataContext)
    const { user } = useContext(UserContext)
    const { uploadId } = useParams()
    const history = useHistory()
    const [uploadData, setUploadData] = useState({})
    const [uploadChanges, setUploadChangeData] = useState({})
    const [reviewText, setReviewText] = useState(null)
    const { API_URL } = config

    useEffect(() => {
        if (!(uploadId === null || isNaN(uploadId))) {
            actions.getExistingSubmission(authenticatedFetch, API_URL, uploadId, setUploadData)
        }
    }, [uploadId])

    useEffect(() => {
        if (uploadData && uploadData.upload) {
            setActiveAgency(uploadData.upload.AgencyCode)
            initializeState(uploadData)
            setFacilityID(uploadData.facility.UploadFacilityID)
        }
    }, [uploadData])

    
    useEffect(() => {
        if (!(uploadId === null || isNaN(uploadId))) {
            actions.getUploadChanges(authenticatedFetch, API_URL, uploadId, setUploadChangeData)
        }
    }, [submissionState, uploadId])

    useEffect(() => {
        if (!(uploadId === null || isNaN(uploadId))) {
            actions.getReviewText(authenticatedFetch, API_URL, uploadId, setReviewText)
        }
    }, [submissionState, uploadId])

    const rejectUpload = useCallback(
        comments => {
            if (!(uploadId === null || isNaN(uploadId)) && user && user.profile) {
                actions.reject(authenticatedFetch, API_URL, history, uploadId, comments, user.profile.name)
            }
        },
        [submissionState, uploadId]
    )

    const approveUpload = useCallback(
        comments => {
            if (!(uploadId === null || isNaN(uploadId))) {
                actions.approve(authenticatedFetch, API_URL, history, uploadId, comments)
            }
        },
        [submissionState, uploadId]
    )

    return (
        <ExistingDataContext.Provider
            value={{
                uploadData,
                uploadChanges,
                rejectUpload,
                approveUpload,
                reviewText
            }}
        >
            {children}
        </ExistingDataContext.Provider>
    )
}

export { ExistingDataContext }
export default withConfig(ExistingDataContextProvider)
