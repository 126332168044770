import React, { useContext, useState } from 'react'

import { ParameterContext } from '../../../wrappers/ParameterContext'
import InputComparisonOperator from './InputComparisonOperator'
import InputComponent from './InputComponent'


const Input = ({
    fieldName,
    name,
    type,
    helper,
    comparisonOperators,
    register,
    unregister,
    registerParams,
    setValue,
    errors,
    clearError,
    minValue,
    maxValue,
    formWidth,
    formName,
    maxLength,
}) => {
    const { params } = useContext(ParameterContext)
    const filterName = `${name}Value`
    const comparisonOperatorName = `${name}Operator`
    const initialFilterValues =
        params[formName] && params[formName][filterName]
            ? params[formName][filterName].split(',')
            : ['']
    const initialComparisonOperatorValues =
        comparisonOperators &&
        params[formName] &&
        params[formName][comparisonOperatorName]
            ? params[formName][comparisonOperatorName].split(',')
            : ['']

    const [between, setBetween] = useState(
        initialComparisonOperatorValues.length > 1
    )

    // toggle between state
    const checkForBetweenOperator = e => {
        if (e.currentTarget.value === 'BETWEEN') {
            setBetween(true)
        } else {
            setBetween(false)
        }
    }

    return (
        <div
            className={`column ${
                formWidth === 'full' ? 'is-one-third' : 'is-full'
            } no-vertical-padding formInputWrapper`}
        >
            <div className="formInputLayout">
                <div className="field">
                    <div className="field-label is-small">
                        <label className="label">{fieldName}</label>
                    </div>
                    <div className="field-body">
                        <div className="field is-expanded">
                            <div className="field has-addons">
                                <InputComparisonOperator
                                    {...{
                                        comparisonOperators,
                                        comparisonOperatorName,
                                        register,
                                        unregister,
                                        between,
                                        initialComparisonOperatorValues,
                                        checkForBetweenOperator,
                                    }}
                                />
                                <InputComponent
                                    {...{
                                        filterName,
                                        type,
                                        errors,
                                        clearError,
                                        initialFilterValues,
                                        registerParams,
                                        register,
                                        unregister,
                                        minValue,
                                        maxValue,
                                        maxLength,
                                        helper,
                                        between,
                                        setValue,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Input
