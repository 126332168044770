import { Fill, Stroke, Style, Text } from 'ol/style'

const activeFill = new Fill({
    color: 'rgba(50, 115, 220, 0.0)',
    // color: 'rgba(61, 61, 61, 0.5)'
})

const activeStroke = new Stroke({
    // color: '#ffffff',
    // width: 1,
    color: '#ee0000',
    width: 2
})

const inactiveFill = new Fill({
    color: 'rgba(61,61,61, 0)',
})

const inactiveStroke = new Stroke({
    color: 'rgba(61, 61, 61, 0.5)',
    width: 1,
})

const activeStyle = { stroke: activeStroke, fill: activeFill, zIndex: 100, opacity: 1 }
const inactiveStyle = { fill: inactiveFill, stroke: inactiveStroke }
const styles = {
    1: new Style(activeStyle),
    0: new Style(inactiveStyle),
    invisible: new Style({
        stroke: null,
        fill: null
    }),
    highlighted: new Style({
        stroke: new Stroke({ color: 'rgb(100, 80, 210)', width: 2 }),
        fill: new Fill({ color: 'rgba(210, 80, 210, 1.0)' }),
    }),
}

const drawStyles = {
    polygonStyles: new Style({
        stroke: new Stroke({color: 'rgb(235, 162, 80)', width: 2}),
        fill: new Fill({color: 'rgba(235, 162, 80, 0.3)'}),
    }),
    getPointerStylesHighlighted: feature => getSelectedStyle(feature, 1),
    getPointerStylesSelected: feature => getSelectedStyle(feature, 0),
    boundingBoxStyles: new Style({
        stroke: new Stroke({color: 'rgb(235, 162, 80)'}),
    }),
}    
const getThemeFeatureStroke = color => {
    if (typeof color === 'string') {
        return color
    } else {
        return 'rgba(255, 255, 255, 0)'
    }
}

const unthemedStyle = {
    fill: new Fill({
        color: 'rgba(61,61,61, 0)'
    }),
    stroke: new Stroke({
        color: 'rgba(61,61,61,0.5)'
    })
}

const getUnthemedStyle = (feature) => {
    const textStyle = new Text({
        fill: new Fill({color: '#222222'}),
        stroke: new Stroke({color: '#222222'}),
        text: feature.get('ID')
    })
    return new Style({
        ...unthemedStyle,
        text: textStyle,
    })
}

const getThemedStyle = (feature) => {
    // const textStyle = new Text({
    //     fill: new Fill({color: '#222222'}),
    //     stroke: new Stroke({color: '#222222'}),
    //     text: feature.get('label')
    // })
    return new Style({
        fill: new Fill({
            color: feature.get('color'),
        }),
        stroke: new Stroke({
            color: getThemeFeatureStroke(
                feature.get('color')
            ),
            width: 0.5,
        }),
        // text: textStyle
    })
}

const getSelectedStyle = (feature, selected) => {
    const textStyle = new Text({
        fill: new Fill({color: 'rgb(255, 255, 255)'}),
        stroke: new Stroke({color: 'rgb(0,0,0)', width: 1}),
        text: feature.get('ID')
    })
    if (selected) {
        return new Style({
            ...activeStyle,
            text: textStyle
        })
    } else {
        return new Style({
            ...unthemedStyle,
            text: textStyle
        })
    }
}

const getHighlightedStyle = (feature) => {
        const textStyle = new Text({
            fill: new Fill({color: 'rgb(255, 255, 255)'}),
            stroke: new Stroke({color: 'rgb(0,0,0)', width: 1}),
            text: feature.get('ID')
        })
        return new Style({
            stroke: new Stroke({ color: 'rgb(100, 80, 210)', width: 2 }),
            fill: new Fill({ color: 'rgba(210, 80, 210, 1.0)' }),
            text: textStyle
        })
}

const styleFunction = function(themed, feature, zoomLevel) {
    const selected = feature.get('selected')
    const displayed = feature.get('displayed')
    const highlighted = feature.get('highlighted')
    if (displayed) {
        if (highlighted) {
            return getHighlightedStyle(feature)
        }
        const size = Math.ceil(zoomLevel)
        if (selected) {
            return getSelectedStyle(feature, selected)
        } else {
            if (themed) {
                try {
                    // const style = qualityStyles[analyte][facilityType][size]
                    // return style
                    return getThemedStyle(feature)
                } catch(e) {
                    console.log('e:', e)
                    // console.log('facilityStyles:', facilityStyles)
                    console.log('size:', size)
                    console.log('color:', feature.get('MostRecentNitrateConcentration'))
                }
            } else {
                return getUnthemedStyle(feature)
            }
        }
    }
    return styles.invisible
}

export { drawStyles }
export default styleFunction
