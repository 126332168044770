import React, { createContext } from 'react'

const PanelStyleContext = createContext(null)

const PanelStyleContextProvider = ({ panelClassConfig, children }) => (
    <PanelStyleContext.Provider value={panelClassConfig}>
        {children}
    </PanelStyleContext.Provider>
)

export { PanelStyleContext }
export default PanelStyleContextProvider
