let settings = {
    authority: process.env.REACT_APP_OIDC_AUTHORITY,
    client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
    client_secret: process.env.REACT_APP_OIDC_CLIENT_SECRET,
    redirect_uri: process.env.REACT_APP_OIDC_CLIENT_REDIRECT_URI,
    silent_redirect_uri: process.env.REACT_APP_OIDC_CLIENT_SILENT_REDIRECT_URI,
    response_type: process.env.REACT_APP_OIDC_RESPONSE_TYPE,
    scope: process.env.REACT_APP_OIDC_SCOPE,
    post_logout_redirect_uri: process.env.REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI,
    automaticSilentRenew: true
    // loadUserInfo: true
}

export default settings