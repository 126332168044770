import React from 'react'

const Burger = ({ expanded, onClick }) => {
    return (
        <div
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded={expanded}
            data-target="navbarBasicExample"
            onClick={() => onClick(!expanded)}
        >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
        </div>
    )
}

export default Burger
