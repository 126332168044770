import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react'
import Select from 'react-select'
import { useForm } from 'react-hook-form'
import merge from 'lodash.merge'

import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import { DataContext } from '../DataContext'
import SelectStyles from '../../../elem/form/SelectStyles'
import withConfig from '../../../wrappers/withConfig'
import {
    aliasHelperText,
} from '../../../../utils/submissions/helperText'
import toast from '../../../elem/Toast'

const ExistingWellComponent = withConfig(({ config, setFacilityData, targetField, setTargetField, setContinueDisabled }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { activeAgency, setFormMethods } = useContext(DataContext)
    const formMethods = useForm({ mode: 'onChange' })
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('')
    const [valuesChanged, setValuesChanged] = useState(false)
    const [error, setError] = useState(null)
    const { register, handleSubmit } = formMethods
    const searchDisabled = useMemo(() => !value, [value])
    const { API_URL } = config

    useEffect(() => {
        if (value !== "" && !valuesChanged) {
            setContinueDisabled(false)
        } else {
            setContinueDisabled(true)
        }
    }, [valuesChanged, value])

    useEffect(() => {
        setFormMethods(formMethods)
    }, [])

    const search = (v, field) => {
        if (v === '') {
            setError('Please enter a value.')
            setFacilityData({})
            return
        }
        if (field === '' || field === null) {
            setError('Please select an identifier for the existing sampling point.')
            setFacilityData({})
            return
        }
        if (field === "PermitNumber") {
            if (parseFloat(v) !== parseInt(v)) {
                setError('Value should be an integer.')
                setFacilityData({})
                return
            }
            if (parseInt(v) < 1) {
                setError('Value cannot be less than 1.')
                setFacilityData({})
                return
            }
        }
        setLoading(true)
        setFacilityData({})
        authenticatedFetch(
            `${API_URL}/upload/facilityInfo?columnName=${field}&columnValue=${v}&agencyCode=${activeAgency}`
        )
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setFacilityData(response.facilityInfo)
                setError(null)
                setValuesChanged(false)
            })
            .catch(e => {
                setError(e.message)
                setFacilityData({})
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <form
                className="form"
                onSubmit={handleSubmit(() => search(value, targetField))}
            >
                <div className="field is-horizontal is-align-items-center">
                    <div className="field-label">
                        <label className="label">Identify Sampling Point By:</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <Select
                                className="select is-multiple is-fullwidth reactSelect"
                                classNamePrefix="reactSelect"
                                options={[
                                    {
                                        value: 'PermitNumber',
                                        label: 'Permit Number',
                                    },
                                    {
                                        value: 'FacilityID',
                                        label: 'RBDMS ID',
                                    },
                                    { value: 'Alias', label: 'Sampling Point Alias' },
                                ]}
                                name="columnName"
                                // ref={register}
                                styles={SelectStyles}
                                menuPlacement="auto"
                                onChange={data => {
                                    setValue('')
                                    setTargetField(data.value)
                                    setValuesChanged(true)
                                }}
                            />
                        </div>
                        <div className="field">
                            <input
                                className={`input is-fullwidth`}
                                onChange={e => {
                                    setValuesChanged(true)
                                    setValue(e.target.value)
                                }}
                                value={value}
                                ref={register}
                                name="columnValue"
                                type={`${targetField === 'PermitNumber' ? 'number' : null}`}
                            />
                        </div>
                        <div className="field">
                            <button
                                type="submit"
                                disabled={`${searchDisabled ? "true" : ""}`}
                                className={`button is-primary ${loading ? 'is-loading' : ''}`}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                {error ? <div className="has-text-danger">{error}</div> : null}
            </form>
        </>
    )
})

const NewWellComponent = withConfig(({ config, setFacilityData , setContinueDisabled }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { activeAgency, setFormMethods } = useContext(DataContext)
    const [alias, setAlias] = useState(null)
    const [valuesChanged, setValuesChanged] = useState(false)
    const searchDisabled = useMemo(() => (!alias ), [alias])
    
    useEffect(() => {
        if ((!alias) || !valuesChanged) {
            setContinueDisabled(false)
        } else {
            setContinueDisabled(true)
        }
    }, [valuesChanged, alias])

    const [loading, setLoading] = useState(false)
    const formMethods = useForm({ mode: 'onChange' })
    const [error, setError] = useState(null)
    const { handleSubmit } = formMethods
    const { API_URL } = config

    useEffect(() => {
        setFormMethods(formMethods)
    }, [])

    const search = () => {
        setLoading(true)
        setFacilityData({})
        authenticatedFetch(
            `${API_URL}/upload/facilitySearch?alias=${alias}&agencyCode=${activeAgency}`
        )
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                const aliasObj = {
                    Alias: alias
                }
                const data = merge({facilityAlias: aliasObj}, response.facilityInfo)
                setFacilityData(data)
                setError(null)
                setValuesChanged(false)
            })
            .catch(e => {
                setError(e.message)
                setFacilityData({})
            })
            .finally(() => setLoading(false))
    }

    return (
        <form className="form" onSubmit={handleSubmit(d => search())}>
            <div className="is-size-4 has-text-centered matchingFacilityText">
                <span className="has-text-weight-semibold">Sampling Point Identifiers: </span>
                <span className="help has-text-grey">
                    <p>
                        Sampling Point Alias is an optional field. 
                        If Sampling Point Alias is left blank, the sampling point will be identified by an RBDMS ID assigned after this form is accepted.
                    </p>
                </span>
            </div>
            <div className="columns is-vcentered">
                <div className="column is-12">
                    <div className="field is-horizontal ">
                        <div className="field-label is-align-items-center">
                            <label className="label">Sampling Point Alias:</label>
                        </div>
                        <div className="field-body">
                            <div className="field has-addons">
                                <div className="control is-expanded">
                                    <input
                                        className="input is-fullwidth "
                                        name={'Alias'}
                                        onChange={e => {
                                            setValuesChanged(true)
                                            setAlias(e.target.value)
                                        }}
                                    />
                                    <span className="help has-text-grey has-text-centered">{aliasHelperText}</span>
                                </div>
                                <div className="control">
                                    <button type="submit" disabled={`${searchDisabled ? "true" : ""}`} className={`button is-primary ${loading ? 'is-loading' : ''}`}>
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {error ? <div className="has-text-danger">{error}</div> : null}
        </form>
    )
})

const ToggleComponent = ({ updateFunction, setFacilityData, setFacilityID }) => {
    return (
        <form className="form">
            <div className="field is-horizontal is-align-items-center">
                <div className="field-label">
                    <label className="label">New Sampling Point to Warehouse?</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <Select
                            className="select is-multiple is-fullwidth reactSelect"
                            classNamePrefix="reactSelect"
                            options={[
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ]}
                            styles={SelectStyles}
                            menuPlacement="auto"
                            onChange={data => {
                                setFacilityData({})
                                setFacilityID(null)
                                updateFunction(data.value)
                            }}
                            defaultValue={[{ value: true, label: 'Yes' }]}
                        />
                    </div>
                </div>
            </div>
        </form>
    )
}

const FacilityInformation = ({ facilityData, updateFacilityAlias, duplicateAliasError, targetField, continueDisabled, viewOnly }) => {
    const [ alias, setAlias ] = useState()

    useEffect(() => {
        setAlias(
            facilityData && facilityData.facilityAlias
            ? facilityData.facilityAlias.Alias
            : ''
        )
    }, [facilityData])

    if ((continueDisabled && !viewOnly) || !(facilityData.facility && Object.keys(facilityData.facility).some(x => facilityData.facility[x] != null)) ) {
        return null
    }

    return facilityData && facilityData.facility ? (
        <form className="form is-centered">
            <div className="is-size-4 has-text-weight-semibold has-text-centered matchingFacilityText">
                Sampling Point Information:{' '}
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">Sampling Point Alias: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                            <input
                                className="input is-small"
                                disabled
                                value={alias}
                            />
                            <p className="help has-text-gray">{aliasHelperText}</p>
                            {duplicateAliasError ? <p className="help is-danger ">{duplicateAliasError}</p> : null}

                        </div>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">RBDMS ID: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    facilityData && facilityData.facility
                                        ? facilityData.facility.RBDMSID
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">Permit Number: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    facilityData && facilityData.facility
                                        ? facilityData.facility
                                              .PermitNumber
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">Sampling Point Name: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    facilityData && facilityData.facility
                                        ? facilityData.facility.FacilityName
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">Sampling Point Type: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    facilityData && facilityData.facility
                                        ? facilityData.facility.FacilityTypeString
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">Program: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    facilityData && facilityData.facility
                                        ? facilityData.facility.AgencyString
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    ) : null
}

const SaveAndContinueButton = ({ facilityData, duplicateAliasError, continueDisabled }) => {
    const { setSubmissionState, isSubmitting, setTableData } = useContext(DataContext)

    const mergeFacilityData = useCallback(() => {
        if (duplicateAliasError) {
            toast({
                level: 'error',
                message: 'Please fix errors before continuing.',
                alert: true
            })
        } else {
            setSubmissionState(prevSubmissionState => ({
                aquifer: [],
                construction: [],
                facility: {},
                facilityAlias: {},
                waterLevel: [],
                lithology: [],
                result: [],
                sample: [],
                upload: prevSubmissionState.upload,
                new: Math.random() * 1000,
                ...facilityData
            }))
            setTableData({})
        }
    }, [facilityData, duplicateAliasError])

    return (
        <div className="buttonWrapper">
            <button
                type="button"
                onClick={() => mergeFacilityData()}
                disabled={`${continueDisabled ? "true" : ""}`} 
                className={`button is-medium is-link ${isSubmitting ? 'is-loading' : ''}`}
            >
                {`Continue`}
            </button>
        </div>
    )
}

const FacilitySelectionForm = ({ config }) => {
    const [newWell, setNewWell] = useState(true)
    const { activePanel, activeAgency, submissionState, viewOnly, isReview, setFacilityID } = useContext(DataContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [ continueDisabled, setContinueDisabled] = useState(false)
    const [targetField, setTargetField] = useState(null)
    const [ facilityData, setFacilityData ] = useState(submissionState)
    const [ duplicateAliasError, setDuplicateAliasError ] = useState(null)
    const { API_URL } = config

    const updateFacilityAlias = useCallback(alias => {
        // // do not update the facility alias if it has not changed on blur
        // if (facilityData && facilityData && facilityData.facilityAlias) {
        //     if (alias === facilityData.facilityAlias.Alias) {
        //         return
        //     }
        // }
        // otherwise, make sure the alias is allowed and submit
        authenticatedFetch(
            `${API_URL}/upload/facilitySearch?alias=${alias}&DNRWellID=${null}&agencyCode=${activeAgency}`
        )
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setFacilityData(previousFacilityData => ({
                    ...previousFacilityData,
                    facilityAlias: {
                        ...previousFacilityData.facilityAlias,
                        Alias: alias,
                        IsLegacy: 0
                    }
                }))
                setDuplicateAliasError(null)
            })
            .catch(e => {
                setDuplicateAliasError(e.message)
            })

    }, [facilityData, authenticatedFetch, activeAgency, API_URL])

    useEffect(() => {
        setFacilityData(submissionState)
    }, [submissionState])

    if (viewOnly || isReview) {
        return (
        <div className={`column is-12 ${activePanel !== 'Sampling Point Search' ? 'is-hidden' : ''}`}>
            <div className="columns is-centered">
                <div className="column is-6">
                    <FacilityInformation
                        viewOnly={viewOnly}
                        continueDisabled={continueDisabled}
                        facilityData={facilityData}
                        updateFacilityAlias={updateFacilityAlias}
                        duplicateAliasError={duplicateAliasError}
                        targetField={targetField}
                    />
                </div>
            </div>
        </div>
        )
    }
    return (
        <div
            className={`columns is-multiline ${
                activePanel !== 'Sampling Point Search' ? 'is-hidden' : ''
            }`}
        >
            <div className="column is-12">
                <ToggleComponent updateFunction={d => setNewWell(d)} setFacilityData={setFacilityData} setFacilityID={setFacilityID} />
            </div>
            <div className="column is-12">
                {newWell ? (
                    <NewWellComponent setFacilityData={setFacilityData} setContinueDisabled={setContinueDisabled} />
                ) : (
                    <ExistingWellComponent setFacilityData={setFacilityData} targetField={targetField} setTargetField={setTargetField} setContinueDisabled={setContinueDisabled}/>
                )}
            </div>
            <div className="column is-12">
                <div className="columns is-centered">
                    <div className="column is-6">
                        <FacilityInformation
                            continueDisabled={continueDisabled}
                            facilityData={facilityData}
                            updateFacilityAlias={updateFacilityAlias}
                            duplicateAliasError={duplicateAliasError}
                            targetField={targetField}
                        />
                    </div>
                </div>
            </div>
            <div className="column is-12">
                <SaveAndContinueButton facilityData={facilityData} duplicateAliasError={duplicateAliasError} newWell={newWell} continueDisabled={continueDisabled} />
            </div>
        </div>
    )
}

export default withConfig(FacilitySelectionForm)
