import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const extractNumericalValue = d => {
    if (d && !isNaN(d) && !isNaN(parseFloat(d))) {
        return parseFloat(d)
    } else {
        return replaceQuotesWithNulls(d)
    }
}

const replaceQuotesWithNulls = d => {
    if (d === '') {
        return null
    } else {
        return d
    }
}

const replaceBooleanWithInt = d => {
    if (typeof d === 'boolean') {
        return d ? 1 : 0
    } else {
        return d
    }
}

const extractDateValue = d => {
    if (d === '' || d === null) {
        return null
    } else {
        return new Date(d + ' UTC').toISOString().replace('.000Z', 'Z')
    }
}

export { extractNumericalValue, replaceBooleanWithInt, replaceQuotesWithNulls, extractDateValue }