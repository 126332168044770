import { getDescriptionValue } from './parseColumns'
import { getFieldNameFromParam } from '../stringUtils'

export default (params, filterFields) => {
    if (!filterFields.length || !params) {
        return 'Applied Filters: '
    }
    // filter out the page, pageSize, sort, and sortOrder params
    // then filter out the operator values,
    // then remove 'Value' from the end of the name
    const relevantParamNames = Object.keys(params).filter(
        param => !(param.includes('Page') | param.includes('Sort') | param.includes('Operator'))
    ).map(paramName => paramName.replace('Value', ''))

    // sort the filter fields based on seq
    const sortedFilterFieldNames = filterFields.map((val, idx) => ({ControlName: val.ControlName, seq: idx}))

    // sort the params by seq and then map to a filter string
    const paramStrings = relevantParamNames.sort((a, b) => 
    {
        const filterFieldA = sortedFilterFieldNames.find(x => x.ControlName === a)
        const seqA = filterFieldA ? filterFieldA.seq : 0
        const filterFieldB = sortedFilterFieldNames.find(x => x.ControlName === b)
        const seqB = filterFieldB ? filterFieldB.seq : 0
        return seqA - seqB
    }).map(paramName => {
        const valueString = paramName + 'Value'
        const comparisonOperatorString = paramName + 'Operator'
        const filterItem = filterFields.find(x => x.ControlName === paramName)
        const hasFilterFields = filterItem && !!filterItem.Values
        const filterLabel = getFieldNameFromParam(paramName, filterFields)
        let operator = params[comparisonOperatorString] ? decodeURIComponent(params[comparisonOperatorString]) : '='
        let value = "";
        if (filterItem && filterItem.ControlType === 'MultiSelect') {
            // we provide a list of comma separated comparison operators for
            // multiselect, but they're all the same value (eg =,=,=,=)
            const operatorArray = params[comparisonOperatorString] ? decodeURIComponent(params[comparisonOperatorString]).split(',') : ['=']
            value = decodeURIComponent(params[valueString]).split(',').map(x => getDescriptionValue(x, filterItem)).join(', ')
            operator = operatorArray[0]
        } else {
            value = hasFilterFields ? getDescriptionValue(params[valueString], filterItem) : decodeURIComponent(params[valueString])

            // this deals with the case of between operators -
            // between operators have operators >=; <=
            if (operator.split(',').length > 1) {
                operator = 'between '
                value = value.split(',').join(' and ')
            }
        }

        return `${filterLabel} ${operator} ${value}`
    })
    return 'Applied Filters: ' + paramStrings.join(', ')
}
