import { cloneDeep } from 'lodash'
import React, { useState, useCallback, useEffect, useContext } from 'react'
import { FaCog } from 'react-icons/fa'

import { getAssociatedFilterField } from '../../../../utils/table/parseColumns'
import { PanelStyleContext } from '../../panel/PanelStyleContext'

const TableSettings = ({
    settingsExpanded,
    columns,
    filterFields,
    setHiddenColumns,
    initialHiddenColumns,
    idColumn
}) => {
    const [hiddenColumns, updateHiddenColumns] = useState([])
    const { borderClass } = useContext(PanelStyleContext)

    const isLayerVisible = useCallback(accessor => {
        return hiddenColumns.find(x => x === accessor) ? false : true
    }, [hiddenColumns])
    
    const toggleColumnVisibility = useCallback(
        accessor => {
            const previouslyHidden = !isLayerVisible(accessor)
            let nextHiddenColumns = hiddenColumns
            let hiddenColumnsTemp = cloneDeep(hiddenColumns)
            if (previouslyHidden) {
                const columnIdx = hiddenColumnsTemp.indexOf(accessor)
                hiddenColumnsTemp.splice(columnIdx, 1)
                nextHiddenColumns = hiddenColumnsTemp
            } else {
                nextHiddenColumns = [...hiddenColumns, accessor]
            }
            setHiddenColumns(nextHiddenColumns)
            updateHiddenColumns(nextHiddenColumns)
        },
        [hiddenColumns, isLayerVisible]
    )

    useEffect(() => {
        updateHiddenColumns(initialHiddenColumns)
    }, [initialHiddenColumns])

    const fieldGroups = [...new Set(filterFields.map(x => x.GroupName))].filter(x => x !== null)

    return (
        <div
            className={`columnControlsPositioning ${
                !settingsExpanded ? 'is-hidden' : ''
            }`}
        >
            <div className="mapLayerControlsPadding">
                <div className={`mapLayerControlsWrapper ${borderClass}`}>
                    <div className="mapLayerControlsHeader">
                        <div className="mapLayerControlsTitle is-size-6">
                            <FaCog className="mapLayerIcon" />
                            <span className="mapLayerControlsText has-text-weight-bold">
                                Column Settings
                            </span>
                        </div>
                    </div>
                    <hr className={`${borderClass} mapLayerControlsDivider`} />
                    <div className="columnControlsContent">
                        {fieldGroups.map((groupName, idx) => {
                            const associatedFields = filterFields.filter(x => x.GroupName === groupName).map(x => x.ControlName)
                            const associatedColumns = columns.filter(x => associatedFields.includes(x.accessor))
                            if (associatedColumns.length === 0) {
                                return null
                            }
                            return (
                                <React.Fragment key={`table-settings-${idx}`}>
                                <div className="is-size-6 has-text-weight-bold">
                                    <span className="mapLayerControlsText">
                                        {groupName}
                                    </span>
                                </div>
                                <hr className={`mapLayerControlsDivider`} />
                                <div className="columnControlsPadding">
                                    {associatedColumns.filter(x => x.accessor !== idColumn).map(column => {
                                        const { accessor } = column
                                        const associatedFilterField = getAssociatedFilterField(filterFields, accessor)
                                        const { ColumnTitle: columnTitle, ColumnName: columnName } = associatedFilterField
                                        const visible = isLayerVisible(accessor, hiddenColumns)
                                        return (
                                            <div
                                                className="layerGroupWrapper"
                                                key={`layer-group-${columnName}`}
                                            >
                                                <div 
                                                    className="layerControlWrapper"
                                                    onClick={() => {
                                                        toggleColumnVisibility(accessor)
                                                    }}
                                                >
                                                    <div className="control">
                                                        <input
                                                            className="checkbox"
                                                            type="checkbox"
                                                            checked={visible}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div
                                                        className={`layerControlText is-size-7`}
                                                    >
                                                        {columnTitle}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableSettings
