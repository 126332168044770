const convertFromParams = (sortByField, sortOrder) => {
    if (typeof sortByField === 'undefined') {
        return []
    }
    return [
        {
            id: sortByField,
            desc: sortOrder === 'DESC'
        }
    ]
}

export { convertFromParams }