import React from 'react'

class FieldErrors extends React.PureComponent {
    render() {
        const { formError, warning, apiError } = this.props

        return (
            <>
                <div className="help is-danger inputErrors">
                    {formError && (
                        <span className="inputErrorText">
                            {formError.message}
                        </span>
                    )}
                </div>
                <div className="help has-text-warning-dark inputErrors">
                    {warning && (
                        <span className="inputErrorText">
                            {warning.errorString}
                        </span>
                    )}
                </div>
                <div className="help is-danger inputErrors">
                    {apiError && (
                        <span className="inputErrorText">
                            {apiError.errorString}
                        </span>
                    )}
                </div>
            </>
        )
    }
}

export default FieldErrors
