import React from 'react'
import {
    FaCog,
    FaSlidersH,
    FaThList,
    FaArrowAltCircleUp,
    FaRegArrowAltCircleUp,
} from 'react-icons/fa'

import withConfig from '../../../wrappers/withConfig'
import PanelHeader from '../../../elem/panel/PanelHeader'
import { IconTooltip as Tooltip } from '../../../elem/Tooltip'

export default withConfig(
    ({
        filterExpanded,
        settingsExpanded,
        toggleFilter,
        togglePromotedRecords,
        promotedRecords,
        toggleSettings,
        config,
    }) => {
        const { FACILITY_TITLE: facilityTitle } = config
        return (
            <PanelHeader extraClass={'wellPanelHeader'}>
                <div className="panelHeaderText">{facilityTitle} Explorer</div>
                <div className="panelHeaderControls">
                    <div className="panelHeaderIconWrapper">
                        {!filterExpanded && (
                            <>
                                <div
                                    className="panelHeaderIconDiv"
                                    data-tip={`${
                                        promotedRecords
                                            ? `Do not promote Selected Records`
                                            : `Promote  Selected Records`
                                    }`}
                                    data-for={`well-promoted-records-toggle`}
                                    onClick={() =>
                                        togglePromotedRecords(!promotedRecords)
                                    }
                                >
                                    {promotedRecords ? (
                                        <FaArrowAltCircleUp />
                                    ) : (
                                        <FaRegArrowAltCircleUp />
                                    )}
                                    <Tooltip
                                        id={'well-promoted-records-toggle'}
                                    />
                                </div>
                                <div
                                    className="panelHeaderIconDiv"
                                    data-tip={`${
                                        settingsExpanded
                                            ? `Hide ${facilityTitle} Table Settings`
                                            : `View ${facilityTitle} Table Settings`
                                    }`}
                                    data-for={`well-settings-toggle`}
                                    onClick={() =>
                                        toggleSettings(!settingsExpanded)
                                    }
                                >
                                    <FaCog />
                                    <Tooltip id={'well-settings-toggle'} />
                                </div>
                            </>
                        )}
                        <div
                            className="panelHeaderIconDiv"
                            data-tip={`${
                                filterExpanded
                                    ? `View ${facilityTitle} List`
                                    : `View ${facilityTitle} Filter`
                            }`}
                            data-for={`well-view-toggle`}
                            onClick={() => toggleFilter(!filterExpanded)}
                        >
                            {filterExpanded ? <FaThList /> : <FaSlidersH />}
                            <Tooltip id={'well-view-toggle'} />
                        </div>
                        <Tooltip
                            id={'well-export-button'}
                            className={'is-white'}
                        />
                    </div>
                </div>
            </PanelHeader>
        )
    }
)
